import React, { useCallback } from "react";
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  GridSortModel,
} from "@mui/x-data-grid-pro-v5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PartialStep,
  Questionnaire,
  QuestionnaireType,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectQuestionnaireName,
  selectRemoveSpecFromScopeLoading,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getAirbusSpecificationTableColumns } from "./airbus-specification-table.columns";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./airbus-specification-table.styled";

type Props = {
  hideCompletedTableLines: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  isTableDisabled: boolean;
  sortModel: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
};

export const AirbusSpecificationTable: React.FC<Props> = ({
  hideCompletedTableLines,
  page,
  setPage,
  pageSize,
  isTableDisabled,
  sortModel,
  onSortModelChange,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const questionnaireName: Questionnaire = useSelector(selectQuestionnaireName);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const tableData = useSelector(selectSpecsOfScope);
  const rows = tableData?.results?.data;
  const isLoadingFetchTableRows = useSelector(selectSpecsOfScopeLoading);
  const isLoadingDeleteProcessControlSpec = useSelector(selectRemoveSpecFromScopeLoading);
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const columns = getAirbusSpecificationTableColumns(
    isFormDisplayForValidationManagement,
    isTableDisabled
  );
  const specTypesForTableSpecs = ["A2ERD", "A2TS"];

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "removeRowButton" && !isTableDisabled) {
      const id = params.row.specification_id;

      dispatch(
        vendorManagementActions.removeSpecFromScopeRequest({
          specification_id: id,
          companyId: companyId,
          onSuccess: () => onRemoveSuccess(params.row?.entity_id),
        })
      );
    }
    if (!params.colDef.editable) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({
              ...acc,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  };

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const specification_id = newRow?.specification_id;
    let updatedField = null;
    let updatedFieldKey = null;
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        updatedField = { [key]: newRow[key] };
        updatedFieldKey = [key];
      }
    });

    if (updatedField) {
      dispatch(
        vendorManagementActions.saveSpecOfScopeRequest({
          specificationId: specification_id,
          spec: updatedField,
          companyId: companyId,
          onSuccess: onUpdateSuccess,
        })
      );
    }
    return newRow;
  };

  const onRemoveSuccess = (entity_id: number) => {
    checkInputChange();
    dispatch(
      vendorManagementActions.decreaseUnapprovedThreadsCountOfStepIfRemovedLineHasUnresolvedThread({
        stepId: step.step,
        entityIdOfLine: entity_id,
      })
    );
    reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps();
    reFetchTableDataToGetUpdatedCompletedLineCount();
  };

  const reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps = () => {
    dispatch(
      vendorManagementActions.fetchStepsRequestWithoutCausingReRendering({
        companyId: companyId,
        questionnaireType: QuestionnaireType[questionnaireName],
      })
    );
  };

  const onUpdateSuccess = () => {
    reFetchTableDataToGetUpdatedCompletedLineCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCompletedLineCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        specType: specTypesForTableSpecs,
        pagination: { page: page, pageSize: pageSize },
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStep("is_completed", false);
    } else {
      updateFieldStatusOfStep("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStep = (key: "is_completed" | "is_partially_filled", value: boolean) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (step?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    }
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={!isFormDisplayForValidationManagement ? 32 : 40}
        pagination
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        page={!isFetchSpecByClickedThread ? page - 1 : 1}
        pageSize={pageSize}
        rowCount={tableData?.count ? tableData.count : 0}
        rowsPerPageOptions={[pageSize]}
        onPageChange={(newPage) => setPage(newPage + 1)}
        autoHeight
        disableSelectionOnClick
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        loading={isLoadingFetchTableRows || isLoadingDeleteProcessControlSpec}
        getRowId={(row) => `${row.name}-${row.specification_id}`}
        getRowClassName={(params) => {
          const isDisabledStatus = params?.row?.approval_status === "approved";
          return isDisabledStatus ? "disabled-row" : "";
        }}
      />
    </>
  );
};
