/* eslint-disable no-use-before-define */
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { CustomTypography } from "@next/components/custom-typography";
import { PO_GRID_SUPPLIER_FILTER } from "@next/constants/data-grid-state-constants";
import { FavoriteSupplier, workspaceNextActions } from "@next/modules/workspace/redux";
import {
  selectFavoriteSuppliers,
  selectFavoriteSuppliersLoading,
} from "@next/modules/workspace/redux/selectors";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "services/profile";
import { getProfilePreferences } from "services/profile/profile.selectors";
import { debounce } from "lodash";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)`,
    },
  })
);

const Container = styled(Box)({
  width: "268px",
});

const OptionName = styled(CustomTypography)({
  marginLeft: "8px",
  width: "140px",
});

type Props = {
  selectedSuppliers: FavoriteSupplier[];
  setSelectedSuppliers: (value: FavoriteSupplier[]) => void;
  language?: string;
};

const OrdersSuppliersAutocompleteDropdown: FC<Props> = ({
  selectedSuppliers,
  setSelectedSuppliers,
  language,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profilePreferences = useSelector(getProfilePreferences);
  const suppliers = (useSelector(selectFavoriteSuppliers) || []) as FavoriteSupplier[];
  const isLoading = useSelector(selectFavoriteSuppliersLoading);

  const { editProfilePreferences } = profileActions;

  useEffect(() => {
    const storedSupplierFilter = profilePreferences[PO_GRID_SUPPLIER_FILTER];
    if (storedSupplierFilter && !_.isEqual(storedSupplierFilter?.data, selectedSuppliers)) {
      setSelectedSuppliers(storedSupplierFilter?.data);
    }
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const handleDebounceSearch = useCallback(
    debounce((_searchValue: string) => {
      try {
        dispatch(
          workspaceNextActions.fetchFavoriteSuppliersRequest({
            query: `pageSize=10&name=${_searchValue}`,
          })
        );
      } catch (err) {
        console.error(err);
      }
    }, 1000),
    [dispatch]
  );

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: FavoriteSupplier,
    { selected }: AutocompleteRenderOptionState
  ) => {
    return (
      <li {...props} key={option.pk}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Avatar src={option?.picture}> </Avatar>

            <OptionName tooltipTitle={option?.name}>{option?.name}</OptionName>
          </Box>

          <Checkbox checked={selected} />
        </Box>
      </li>
    );
  };

  const renderTags = (tags: FavoriteSupplier[]) => {
    return (
      <Box pl={1} maxWidth="100px">
        {tags.length > 1 ? (
          <CustomTypography variant="body1">
            {t("orders:filterItems", { count: tags.length })}
          </CustomTypography>
        ) : (
          <CustomTypography variant="body1">{tags?.[0]?.name}</CustomTypography>
        )}
      </Box>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={null}
        placeholder={
          selectedSuppliers?.length ? t("orders:selectSupplier") : t("orders:allSuppliers")
        }
      />
    );
  };

  const onChange = (_event: any, newSupplierSelection: FavoriteSupplier[]) => {
    setSelectedSuppliers(newSupplierSelection);
    dispatch(
      editProfilePreferences({
        type: PO_GRID_SUPPLIER_FILTER,
        data: newSupplierSelection,
      })
    );
  };

  useEffect(() => {
    handleDebounceSearch(searchValue);
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [searchValue]);

  return (
    <Container>
      <Autocomplete
        multiple
        size="small"
        loading={isLoading}
        options={suppliers}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, values) => option.pk === values.pk}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        sx={{ width: language == "en" ? "290px" : "378px" }}
        value={selectedSuppliers}
        onChange={onChange}
        classes={{ paper: classes.paper }}
        inputValue={searchValue}
        onInputChange={(event, newInputValue) => {
          setSearchValue(newInputValue);
        }}
      />
    </Container>
  );
};

export default OrdersSuppliersAutocompleteDropdown;
