import { Box, Theme, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import { quoteComparisonContext } from "../../components/quote-comparison/quote-comparison.context";
import { groupAndMergeBySupplierId } from "./quote-comparison-award-drawer-footer.utils";
import { useParams } from "react-router-dom";
import { selectProceedQuoteComparisonSelectionLoading } from "../../redux/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import SyncQuotesToggle from "../../components/sync-quotes-toggle/sync-quotes-toggle";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { ErpConfigurationType } from "@next/modules/app/components/app-bar-next/app-bar-next-erp-configuration";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    button: {
      width: "100%",
      height: 40,
    },
  })
);

export const QuoteComparisonAwardDrawerFooter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const proceedQuoteComparisonSelectionLoading = useSelector(
    selectProceedQuoteComparisonSelectionLoading
  );
  const rfqDetails = useSelector(getRfqDetails);
  const erpConfiguration = useSelector(getErpConfiguration);
  const isInforErp = erpConfiguration?.erp_type?.value === ErpConfigurationType.INFOR;
  const { selectedData } = useContext(quoteComparisonContext);
  const classes = useStyles();
  const params = useParams<{ id?: string }>();

  const [syncContract, setSyncContract] = React.useState(true);

  const onClickProceed = () => {
    const rfqId = params?.id || rfqDetails?.pk;
    if (!rfqId) return null;

    dispatch(
      workspaceNextActions.proceedQuoteComparisonSelectionRequest({
        rfq_pk: parseInt(rfqId),
        contractData: groupAndMergeBySupplierId(selectedData?.selectedSuppliersParts),
        sync_vend_contracts: isInforErp ? syncContract : undefined,
      })
    );
  };

  return (
    <div className={classes.footer}>
      <Box
        m={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        {isInforErp && <SyncQuotesToggle syncQuote={syncContract} setSyncQuote={setSyncContract} />}
        <CustomButton
          onClick={onClickProceed}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          fullWidth
          loading={proceedQuoteComparisonSelectionLoading}
          data-pid="btnQCProceed"
        >
          <Typography variant="body2">{t("workspaceNext:quoteComparison:proceed")}</Typography>
        </CustomButton>
      </Box>
    </div>
  );
};
