import React from "react";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EditableDateTemplateCell } from "../table-cells/editable-date-template-cell";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import { ValidationCell } from "../table-cells/validation-cell";
import { RemoveBtnCell } from "../table-cells/remove-btn-cell";
import { SpecCell } from "../table-cells/spec-cell";

export const selectComplianceMenuItems = [
  { key: "fully_compliant", value: "Fully compliant" },
  { key: "partially_compliant", value: "Partially compliant" },
  { key: "not_compliant", value: "Not compliant" },
];
const sinceOptionsForDateTemplateCell = ["fully_compliant"];
const byOptionsForDateTemplateCell = ["partially_compliant", "not_compliant"];

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getSubtierReadinessFormTableColumns = (
  isFormDisplayForValidationManagement: boolean,
  isTableDisabled: boolean,
  supplier_title: string
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      sortable: true,
      field: "name",
      headerName: "Spec",
      renderCell: (params) =>
        !isFormDisplayForValidationManagement ? (
          <SpecCell {...params} text={params?.row?.name} />
        ) : undefined,
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "compliance",
      headerName: "Compliance",
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => {
        const complianceText =
          selectComplianceMenuItems.find((item) => item.key === params.value)?.value ||
          params.value;

        return (
          <EditableTemplateCell
            {...params}
            icon={
              !(isTableDisabled || params.row?.approval_status === "approved") ? (
                <KeyboardArrowDownIcon />
              ) : null
            }
            placeholderText="Select an option"
            value={complianceText}
          />
        );
      },
    },
    {
      ...defaultColumnConfig,
      sortable: true,
      field: "poe",
      headerName: "POE (Point of Embodiment)",
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableDateTemplateCell
          checkField={"compliance"}
          sinceOptions={sinceOptionsForDateTemplateCell}
          byOptions={byOptionsForDateTemplateCell}
          isTableDisabled={isTableDisabled || params.row?.approval_status === "approved"}
          {...params}
        />
      ),
    },
  ];

  const lastColumn = !isFormDisplayForValidationManagement
    ? {
        ...defaultColumnConfig,
        field: "removeRowButton",
        headerName: " ",
        align: "center" as const,
        renderCell: (params: GridRenderCellParams) => {
          return <RemoveBtnCell disabled={isTableDisabled} {...params} />;
        },
        flex: 0.1,
      }
    : {
        ...defaultColumnConfig,
        field: "action",
        headerName: "Action",
        renderCell: (params: GridRenderCellParams) => {
          return <ValidationCell params={params} supplier_title={supplier_title} />;
        },
        minWidth: 180,
      };

  columns.push(lastColumn);

  return columns;
};
