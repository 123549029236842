import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { GenericUser } from "@next/modules/profile/redux";
import {
  GroupedCreateMultiContractData,
  Quotation,
} from "../components/multi-contract/create-multi-contract-table.types";
import { RFQSupplierType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.types";
import {
  BomFilesUploadReport,
  BomFilesUploadReportStatusData,
  UploadReportStatus,
} from "@next/redux/types";

export type ProjectState = {
  projectStats?: ProjectType;
  projectStatsLoading: boolean;
  projectStatsError?: Error | undefined;

  projectCompanyParts: ProjectCompanyPart[];
  projectCompanyPartsLoading: boolean;
  projectCompanyPartsError?: Error | undefined;
  projectCompanyPartsCount: number;
  projectCompanyPartsLastQuery?: string | undefined;
  projectCompanyPartsSelectAll: boolean;
  projectCompanyPartsFiltered: boolean;

  projectCompanyOrders: ProjectCompanyOrder[];
  projectCompanyOrdersLoading: boolean;
  projectCompanyOrdersError?: Error | undefined;
  projectCompanyOrdersLastQuery?: string | undefined;
  projectCompanyOrdersCounts: ProjectCompanyOrderCounts | undefined;

  projectCreatePartLoading: boolean;
  projectCreatePartError?: Error | undefined;

  projectUpdatePartLoading: boolean;
  projectUpdatePartError?: Error | undefined;

  projectBulkInsertPartLoading: boolean;
  projectBulkInsertPartError?: Error | undefined;

  projectDeletePartLoading: boolean;
  projectDeletePartError?: Error | undefined;

  projectBulkDeletePartLoading: boolean;
  projectBulkDeletePartError?: Error | undefined;

  projectClonePartLoading: boolean;
  projectClonePartError?: Error | undefined;

  createRFQLoading: boolean;
  createRFQError?: Error | undefined;

  updateRFQLoading: boolean;
  updateRFQError?: Error | undefined;

  selectedPart: ProjectCompanyPart | undefined;
  selectedPartRows: ProjectCompanyPart[];

  attachPurchaseOrderLoading: boolean;
  attachPurchaseOrderError?: Error | undefined;

  downloadAxyaServtechLoading: boolean;
  downloadAxyaServtechError?: Error | undefined;

  downloadQuotationFileLoading: boolean;
  downloadQuotationFileError?: Error | undefined;

  projects: ProjectType[];
  projectsCount: number;
  projectsLoading: boolean;
  projectsError?: Error | undefined;

  projectCreateLoading: boolean;
  projectCreateError?: Error | undefined;

  projectUpdateLoading: boolean;
  projectUpdateError?: Error | undefined;

  projectDeleteLoading: boolean;
  projectDeleteError?: Error | undefined;

  createMultiContractRFQLoading: boolean;
  createMultiContractRFQError?: Error | undefined;
  createMultiContractRFQData?: ProjectCompanyOrder[];

  exportMultiContractRFQExcelLoading: boolean;
  exportMultiContractRFQExcelError?: Error | undefined;

  importMultiContractRFQExcelLoading: boolean;
  importMultiContractRFQExcelError?: Error | undefined;
  importMultiContractRFQExcelReport: {
    payload: GroupedCreateMultiContractData[];
    parts_left: number[];
    errors: any[];
  };

  uploadBomFilesLoading: boolean;
  uploadBomFilesError?: Error | undefined;

  fetchBomFilesUploadReportLoading: boolean;
  fetchBomFilesUploadReportError?: Error | undefined;
  bomFilesUploadReport: BomFilesUploadReport | undefined;

  uploadBulkPartLoading: boolean;
  uploadBulkPartError?: Error | undefined;

  fetchBulkPartImportReportLoading: boolean;
  fetchBulkPartImportReportError?: Error | undefined;
  bulkPartImportReport: BulkPartsUploadReport | undefined;

  fetchProjectTemplatesLoading: boolean;
  fetchProjectTemplatesError?: Error | undefined;
  projectTemplates: ProjectTemplate[];

  updateProjectTemplateLoading: boolean;
  updateProjectTemplateError?: Error | undefined;
};

export enum RFQStatus {
  WAITING = "Waiting",
  CANCELED = "Canceled",
  CONTRACT = "Contract",
  ON_HOLD = "ON_HOLD",
  ACTIVE = "Active",
}

export type ProjectStatsInput = {
  projectCode: ProjectType["code"];
  rfqId?: number;
  clearExisting?: boolean;
  onSuccess?: (response: ProjectType) => void;
};

export type ProjectType = {
  pk: number;
  code: string;
  display_name: string;
  company: number;
  total_parts_count: number;
  parts_in_rfq_count: number;
  parts_contracted_count: number;
  total_price: number;
  fixed_fees: number;
  attached_files_count: number;
  template?: ProjectTemplate;
  can_safe_delete: boolean;
};

export type ProjectCompanyPartInput = {
  projectCode: ProjectType["code"];
  query: string;
};

export type ProjectCodes = {
  projectCodes: string[];
};

export type ProjectCompanyPart = {
  additional_details: string;
  alloy: string;
  alloy_pk?: number;
  color?: any;
  default_file_preview?: any;
  delay?: number;
  delay_unit: string;
  extra_fields?: any;
  material?: any;
  name: string;
  part_uploads_list: PartUpload[];
  files: number[];
  pk: number | string;
  preview?: any;
  processes: Process[];
  project: number;
  rfq: Rfq;
  quantity: number;
  quotation_extra_fields?: QuotationExtraField;
  tags: Tag[];
  total_price?: number;
  treatment?: any;
  treatment_pk?: any;
  winner: CompanyInfo;
  price?: number;
};

export type PartUpload = {
  pk: number;
  file_extension: string;
  file_name: string;
  file_size: number;
  file_url: string;
  file_preview_url?: null;
  file_uploaded?: number;
  file_stl?: null;
  file_stl_visibility?: null;
  stl_conversion_task_status?: string;
  stl_visibility_detection_task_status?: string;
  stl_visibility_exit_code?: number;
};

export type Process = {
  id: number;
  type: string;
  name: string;
  label: string;
};

export type ProjectCompanyOrderInput = {
  query: string;
  onSuccess?: (
    response: {
      results: ProjectCompanyOrder[];
    } & ProjectCompanyOrderCounts
  ) => void;
};

export type ProjectCompanyOrder = {
  additional_details: string;
  company_name: string;
  created_at: Date;
  created_by: GenericUser | string;
  deadline: string;
  order_status: OrderStatus;
  pk: number;
  rfq_display_name: string;
  rfq_name: string;
  rfq_number: string;
  status: RFQStatus;
  tag: Tag;
  cancellation_reason: CancellationReason;
  collaborators: GenericUser[];
  nda?: any;
  supplier_signed_nda?: any;
  cancellation_note: string;
  intent_to_bid_due_date?: any;
  is_private: boolean;
  private_recipients: string[];
  is_rating_requested: boolean;
  delay?: number;
  delay_unit: string;
  total_price?: number;
  winner: CompanyInfo;
  winner_date?: Date;
  parts_count: number;
  parts_quantity: number;
  part_previews: string[];
  po_file: PoFile;
  quotations_count: number;
  questions_count: number;
  is_high_priority: boolean;
  rating_score: RatingScore;
  rating_token: string;
  intent_to_bid_count: number;
  unseen_questions_count: number;
};

export interface BrpWinnerQuotation {
  additional_details: string;
  commission_fee: null;
  company_info: CompanyInfo;
  created_at: string;
  delay: null;
  delay_unit: null;
  fixed_charge: null;
  human_delay: string;
  net_d: { days: null };
  parts: ProjectCompanyPart[];
  pk: number;
  quotation_uploads_list: PartUpload[];
  rfq: number;
  rfq_info: RfqInfo;
  total_price: null;
}

export interface OrderStatus {
  slug: string;
  label: string;
}

export interface Tag {
  slug: string;
  name: string;
  description: string;
  template: string;
}

export interface CancellationReason {
  pk: number;
  reason: string;
  reason_short: string;
  hint_message?: any;
}

export interface PoFile {
  pk: number;
  file_name: string;
  file_extension: string;
  file_url: string;
  file_size: number;
  id: number;
  file_pdf: string;
  created_at: Date;
  created_by: number;
}

export interface CriterionScore {
  criteria: string;
  score: number;
}

export interface RatingScore {
  overall_score: number;
  rating_scores: CriterionScore[];
}

export type ProjectCreatePartInput = {
  part: Partial<ProjectCompanyPart>;
  skipRefetchProjectStats?: boolean;
  skipSuccessToast?: boolean;
};

export type ProjectUpdatePartInput = {
  pk: number;
  part: Partial<ProjectCompanyPart>;
  onSuccess?: (response: Partial<ProjectCompanyPart>) => void;
  skipSuccessToast?: boolean;
};

export type ProjectBulkDeletePartInput = {
  partIds: number[];
  projectCode: ProjectType["code"];
  selectAll?: boolean;
  skipSuccessToast?: boolean;
};

export type SuppliersList = {
  name: string;
  picture: string;
};

export type CompanyInfo = {
  company_pk: number;
  company_name: string;
  company_description: string;
  company_website: string;
  company_phone_number: string;
  company_picture: string;
};

export type RfqInfo = {
  pk: number;
  name: string;
  rfq_display_name: string;
  additional_details: string;
  cancellation_note: null;
  cancellation_reason: {};
  company_name: string;
  created_at: string;
  deadline: string;
  status: string;
  tag: null;
  part_extra_fields: PartExtraField[];
  quotation_extra_fields: QuotationExtraField[];
  request_type?: "rfq" | "rfi";
};

export type Rfq = {
  pk: number;
  display_name: string;
  rfq_name?: string;
  deadline?: string;
  suppliersList?: SuppliersList[];
  parts?: Part[];
  additional_details?: string;
  additional_details_rich_text?: string;
  targeted_suppliers?: RFQSupplierType[];
  is_public?: boolean;
  is_private?: boolean;
  quotation_extra_fields?: any;
  company_name?: string;
  company_logo?: string[];
  reference_number?: string;
  rfq_number?: string;
  rfq_display_name?: string;
  quotations?: Quotation[];
  shortlisted?: CompanyInfo[];
  collaborators?: GenericUser[];
  created_by?: string;
  followup_date?: string | null;
  is_contract_completed?: boolean;
  is_no_quote_detected?: boolean;
  lead_time?: number | null;
  hide_quotation_sensitive_data?: boolean;
  winner?: RFQSupplierType;
};

export interface RfqDetails extends Rfq {
  status?: string;
  on_hold_note?: string;
  created_at?: string;
  deadline?: string;
  download_all_url?: string | null;
}

export type Part = {
  name: string;
  pk: number;
  quantity: number;
  is_private?: boolean;
  quotation_extra_fields?: any;
  additional_details?: string;
  extra_fields?: any;
  part_id?: number;
  price?: number;
};

export type UploadPartInput = {
  pk: number;
  files: File[];
  isRFQPart?: boolean;
};

export type UpdateRfqInput = Partial<Omit<RfqDetails, "targeted_suppliers">> & {
  pk: number;
  rfq_cancellation_reason_pk?: number;
  cancellation_note?: string;
  extension_date?: string;
  targeted_suppliers?:
    | {
        id: number;
        target_profiles?: number[];
      }[]
    | RFQSupplierType[];
  refetchData?: boolean;
  onSuccess?: (rfq: Rfq) => void;
  isDetailPage?: boolean;
  isContract?: boolean;
  internal_note_description?: string;
  sync_vend_contracts?: boolean;
};

export type PoFileInput = {
  contractPk: number;
  poFilePk?: number;
};

export type AttachedPoFileInput = {
  pk: number;
  po_file?: PoFile;
};

export type DownloadAxyaServtechInput = {
  rfqId: string;
};

export type BulkDownloadInput = {
  projectCodes: string[];
};

export type ProjectFetchInput = {
  query: string;
};

export type ProjectCreateInput = {
  code: ProjectType["code"];
  display_name: ProjectType["display_name"];
  onSuccess?: (response: any) => void;
};

export type ProjectUpdateInput = {
  code: ProjectType["code"];
  project: Partial<ProjectType>;
};

export type ProjectDeleteInput = {
  code: ProjectType["code"];
  onSuccess?: () => void;
};

export type QuotationExtraField = {
  company?: number;
  context?: string;
  created_at?: string;
  created_by?: number;
  data?: string;
  id: number;
  type?: string;
};

export type PartExtraField = {
  display_name: { en: string; fr: string };
  slug: string;
  type: GridColDef["type"];
  width?: number;
  valueOptions?: GridColDef["valueOptions"];
};

export type ProjectBulkInsertPartInput = {
  code?: ProjectType["code"];
  file: File;
};
export type Address = {
  city: string;
  country: string;
  postal_code: string;
  province: string;
  street: string;
};

export type Instigator = {
  pk: number;
  name: string;
  description: string;
  website: string;
  picture: string;
  uuid: string;
  is_online: boolean;
  last_seen: string;
  address: Address;
};

export type ProjectCreateMultiContractRFQInput = {
  rfq_pk?: number;
  contractData: {
    supplier_id?: GroupedCreateMultiContractData["supplier_id"];
    parts: number[];
  }[];
  sync_vend_contracts?: boolean;
  onSuccess?: () => void;
};

export type ProjectExportMultiContractRFQExcelInput = {
  rfq_pk?: number;
  rfq_name?: string;
};

export type ProjectImportMultiContractRFQExcelInput = {
  rfq_pk?: number;
  file: File;
};

export type TargetedSuppliers = {
  id: number;
  is_online: boolean;
  last_seen: string;
  name: string;
  picture: string;
  uuid: string;
};

export type ExportMyPartsInput = {
  code?: ProjectType["code"];
  headersOnly?: boolean;
};

export type ImportMyPartsInput = {
  code?: ProjectType["code"];
  file: File;
};

export type UploadBomFilesInput = {
  projectId: number;
  file: File;
  attach_unmatched_files?: boolean;
};

export type FetchBomFilesUploadReportInput = {
  projectId: number;
  uuid: string;
};

export type FetchBomPartsUploadReportInput = {
  projectId: number;
  uuid: string;
};

export type UpdateProjectTemplateInput = {
  projectCode: string;
  template: number;
};

export type ClonePartInput = {
  partPk: number;
  projectId?: number;
  skipSuccessToast?: boolean;
  isRFQEditMode?: boolean;
  hanldeRFQEditCallBack?: (part: Part) => void;
};

export type OpenRfqDrawerFromProject = {
  projectCode: string;
  selectedParts: number;
};

export type BulkPartsUploadReport = {
  uuid: string;
  status: UploadReportStatus;
  success_report: any;
  warning_report: BomFilesUploadReportStatusData[];
  error_report: BomFilesUploadReportStatusData[];
  total_parts_count: number;
  parts_processed_count: number;
  parts_success_count: number;
  parts_warning_count: number;
  parts_error_count: number;
};

export type ProjectCompanyOrderCounts = {
  count: number;
  archived_count: number;
  in_progress_count: number;
  on_hold_count: number;
  awarded_count: number;
  completed_count: number;
};

export type ProjectTemplate = {
  id: number;
  name: string;
  company: number;
  part_template: PartExtraField[];
  quotation_template: PartExtraField[];
  part_hidden_columns: string[];
};
