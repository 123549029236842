import { Box, Typography, styled } from "@mui/material";

export const StyledContainer = styled(Box)({
  width: "99%",
  display: "flex",
  gap: "16px",
  alignItems: "center",
});

export const StyledBox = styled(Box)({
  width: "100%",
  borderRadius: "32px",
  display: "flex",
  flexDirection: "column",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
  padding: "24px",
  margin: "0",
  gap: "16px",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
  alignSelf: "center",
}));
