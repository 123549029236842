import { safeCall } from "@next/redux/safeCall";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { vendorManagementService } from "../services/vendor-management-services";
import { vendorManagementActions } from "./slices";
import {
  AddOrUpdateProcessControlStandard,
  AddSpecToScopeRequest,
  AddSpecToSubtierSupplier,
  CreateNewMessageThreadParams,
  CreateNewMessageWithinThreadParams,
  DeleteProcessControlStandard,
  DeleteSpecOfSubtierSupplier,
  FetchDesignAndBuiltDrawings,
  FetchFormDataRequest,
  FetchInternalNoteRequestPayload,
  FetchMessageThreadsOfStepParams,
  FetchMessagesOfThreadParam,
  FetchNumberOfLinesApprovedParams,
  FetchQuestionnairesRequestInput,
  FetchReadinessDashboardDataParams,
  FetchRemovedAddedSpecsOfScopePayload,
  FetchSpecsOfBufferStockInput,
  FetchSpecsOfLeadTimeProductionInput,
  FetchSpecsOfStandardParams,
  FetchSpecsOfSubtierSupplier,
  FetchSpecsTableDataRequestParams,
  FetchStepsRequest,
  FetchSuppliersTableDataRequestParams,
  PaginationAndFilterForScopeSpecs,
  PaginationAndFilterForSpecification,
  QuestionnaireForms,
  RemoveSpecFromScopeRequest,
  SaveAdditionalComment,
  SaveSpecOfScopeRequest,
  SendSupportMessageInput,
  UpdateApprovalFormFile,
  UpdateApprovalStatusOfSpecParams,
  UpdateApprovalStatusOfStepParams,
  UpdateDesignAndBuildDrawing,
  UpdateFormDataRequest,
  UpdateIdentificationDataBody,
  UpdateInternalNoteRequestPayload,
  UpdateLeadTimeImplFormData,
  UpdateMessageWithinThreadParams,
  UpdateSpecOfBufferStockStrategy,
  UpdateSpecOfLeadTimeForProduction,
  UpdateSpecOfSubtierSupplier,
  UpdateStepRequest,
  UpdateSubtierFormData,
  UpdateSubtierOnboardingTableRow,
  UpdateSubtiersOnboardingFormData,
  UpdateThreadStatusParams,
} from "./types";
import snackbarUtils from "@next/utils/snackbarUtils";
import { enqueueSnackbar } from "notistack";
import { GenericObject } from "@next/modules/profile/redux";
import { downloadFileNext } from "@next/utils/fileUtils";

function* handleFetchFormDataRequest({ payload }: PayloadAction<FetchFormDataRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.fetchFormData, payload);
  if (error) {
    yield put(
      vendorManagementActions.fetchFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchFormDataSuccess({
        data: response.data,
        stepName: payload.formName,
        fetcha2psRequest: payload?.fetcha2psRequest,
      })
    );
    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* handleUpdateFormDataRequest({ payload }: PayloadAction<UpdateFormDataRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.updateFormData, payload);

  if (error) {
    yield put(
      vendorManagementActions.updateFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateFormDataSuccess({
        data: response.data,
        stepName: payload.formName,
      })
    );
    if (payload.formName === QuestionnaireForms.Identification) {
      yield put(vendorManagementActions.fetchSupplierByCompanyIdSuccess(response.data));
    }
  }
}

function* handleFetchStepsRequest({ payload }: PayloadAction<FetchStepsRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.fetchSteps, payload);

  if (error) {
    yield put(
      vendorManagementActions.fetchStepsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchStepsSuccess(response.data));

    if (payload?.onSuccess) {
      payload.onSuccess(response.data);
    }
  }
}

function* handleUpdateStepRequest({ payload }: PayloadAction<UpdateStepRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.updateStep, payload);

  if (error) {
    yield put(
      vendorManagementActions.updateStepFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    const isForActivenessChange = Object.keys(payload.data).includes("is_active");
    yield put(
      vendorManagementActions.updateStepSuccess({
        stepId: payload.stepId,
        data: response.data,
        isForActivenessChange: isForActivenessChange,
        next_steps_to_be_completed: payload.data.next_steps_to_be_completed,
      })
    );
    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleProcessControlStandardsRequest({
  payload,
}: PayloadAction<FetchSpecsOfStandardParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchProcessControlStandards,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchProcessControlStandardsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchProcessControlStandardsSuccess(response.data));
  }
}

function* handleFetchAllSpecificationsRequest({
  payload,
}: PayloadAction<PaginationAndFilterForSpecification>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchAllSpecifications,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchAllSpecificationsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchAllSpecificationsSuccess(response.data));
  }
}

function* handleDeleteProcessControlStandardRequest({
  payload,
}: PayloadAction<DeleteProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.deleteProcessControlStandardSuccess(payload));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleAddProcessControlStandardRequest({
  payload,
}: PayloadAction<AddOrUpdateProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.addProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.addProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.addProcessControlStandardSuccess(response.data));
  }
}

function* handleUpdateProcessControlStandardRequest({
  payload,
}: PayloadAction<AddOrUpdateProcessControlStandard>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateProcessControlStandard,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateProcessControlStandardFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateProcessControlStandardSuccess(response.data));
  }
}

function* handleUpdateApprovalFormFileRequest({ payload }: PayloadAction<UpdateApprovalFormFile>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateApprovalFormFile,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateApprovalFormFileFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateApprovalFormFileSuccess(response.data));
  }
}

function* handleDeleteApprovalFormFileRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteApprovalFormFile,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteApprovalFormFileFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.deleteApprovalFormFileSuccess(payload));
  }
}

function* handleFetchAclpClassificationsRequest() {
  const { response, error } = yield safeCall(vendorManagementService.fetchAclpClassifications);

  if (error) {
    yield put(
      vendorManagementActions.fetchAclpClassificationsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchAclpClassificationsSuccess(response.data));
  }
}

function* handleFetchSupplierCategoriesRequest() {
  const { response, error } = yield safeCall(vendorManagementService.fetchSupplierCategories);

  if (error) {
    yield put(
      vendorManagementActions.fetchSupplierCategoriesFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSupplierCategoriesSuccess(response.data));
  }
}

function* handleFetchSpecsOfScopeRequest({
  payload,
}: PayloadAction<PaginationAndFilterForScopeSpecs>) {
  const { response, error } = yield safeCall(vendorManagementService.fetchSpecsOfScope, payload);

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfScopeSuccess({
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData,
        response: response.data,
      })
    );
  }
}

function* handleFetchRemovedSpecsOfScope({
  payload,
}: PayloadAction<FetchRemovedAddedSpecsOfScopePayload>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchRemovedSpecsOfScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchRemovedSpecsOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchRemovedSpecsOfScopeSuccess(response.data));
  }
}

function* handleFetchAddedSpecsOfScope({
  payload,
}: PayloadAction<FetchRemovedAddedSpecsOfScopePayload>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchAddedSpecsOfScope,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchAddedSpecsOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchAddedSpecsOfScopeSuccess(response.data));
  }
}

function* handleRemoveSpecFromScopeRequest({ payload }: PayloadAction<RemoveSpecFromScopeRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.removeSpecFromScope, payload);

  if (error) {
    yield put(
      vendorManagementActions.removeSpecFromScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.removeSpecFromScopeSuccess(payload.specification_id));
    yield put(
      vendorManagementActions.fetchRemovedSpecsOfScopeRequest({
        companyId: payload.companyId,
      })
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleSaveAdditionalCommentFieldOfFormRequest({
  payload,
}: PayloadAction<SaveAdditionalComment>) {
  const { response, error } = yield safeCall(
    vendorManagementService.saveAdditionalCommentFieldOfForm,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.saveAdditionalCommentFieldOfFormFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  }
}

function* handleAddSpecToScopeRequest({ payload }: PayloadAction<AddSpecToScopeRequest>) {
  // Calling all requests first to handle multiple
  // notifications and show only once.
  const results: GenericObject = yield all(
    payload.rows.map((row) =>
      safeCall(vendorManagementService.addSpecToScope, {
        companyId: payload.companyId,
        row,
      })
    )
  );
  const errorResults = results.map((result: any) => result?.error).filter((item: any) => !!item);

  const successResults = results
    .map((result: any) => result?.response?.data)
    .filter((item: any) => !!item);

  if (errorResults?.length > 0) {
    yield put(vendorManagementActions.addSpecToScopeFailure(errorResults?.join(",")));
    yield enqueueSnackbar(errorResults?.join(","), {
      variant: "error",
      autoHideDuration: 6000,
    });
  } else if (successResults?.length > 0) {
    yield put(vendorManagementActions.addSpecToScopeSuccess(payload.rows));
    yield put(
      vendorManagementActions.fetchAddedSpecsOfScopeRequest({
        companyId: payload.companyId,
      })
    );
    if (payload.onSuccess) payload.onSuccess();
  }
}

function* handleSaveSpecOfScopeRequest({ payload }: PayloadAction<SaveSpecOfScopeRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.saveSpecOfScope, payload);

  if (error) {
    yield put(
      vendorManagementActions.saveSpecOfScopeFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.saveSpecOfScopeSuccess({
        specificationId: payload.specificationId,
        spec: response.data,
      })
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleUpdateLeadTimeImplFormDataRequest({
  payload,
}: PayloadAction<UpdateLeadTimeImplFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateLeadTimeImplFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateLeadTimeImplFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateLeadTimeImplFormDataSuccess(response.data));
  }
}

function* handleUpdateSubtiersFormDataRequest({ payload }: PayloadAction<UpdateSubtierFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtiersFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtierFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateSubtierFormDataSuccess(response.data));
  }
}

function* handleFetchSubtierCompaniesRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSubtierCompaniesRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSubtierCompaniesFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSubtierCompaniesSuccess(response.data));
  }
}

function* handleFetchSpecsOfSubtierSupplierRequest({
  payload,
}: PayloadAction<FetchSpecsOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfSubtierSupplierFailure({
        error: new Error("Something went wrong. Please try again."),
        supplierId: payload.supplierId,
      })
    );
  } else {
    const specOfSubtierSupplier = {
      supplier_id: payload.supplierId,
      rows: response.data.data,
    };
    yield put(vendorManagementActions.fetchSpecsOfSubtierSupplierSuccess(specOfSubtierSupplier));
  }
}

function* handleUpdateSpecOfSubtierSupplierRequest({
  payload,
}: PayloadAction<UpdateSpecOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSpecOfSubtierSupplierSuccess({
        spec: response.data,
        supplierId: payload.supplierId,
        specificationId: payload.specificationId,
      })
    );
  }
}

function* handleDeleteSpecOfSubtierSupplierRequest({
  payload,
}: PayloadAction<DeleteSpecOfSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.deleteSpecOfSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.deleteSpecOfSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.deleteSpecOfSubtierSupplierSuccess({
        supplierId: payload.supplierId,
        specificationId: payload.specificationId,
      })
    );
    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleAddSpecToSubtierSupplierRequest({
  payload,
}: PayloadAction<AddSpecToSubtierSupplier>) {
  const { response, error } = yield safeCall(
    vendorManagementService.addSpecToSubtierSupplier,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.addSpecToSubtierSupplierFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.addSpecToSubtierSupplierSuccess({
        supplierId: payload.supplierId,
        spec: response.data,
      })
    );
  }
}

function* handleFetchSubtierOnboardingTableDataRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSubtierOnboardingTableData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSubtierOnboardingTableDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSubtierOnboardingTableDataSuccess(response.data.results.data)
    );
  }
}

function* handleUpdateSubtiersOnboardingFormDataRequest({
  payload,
}: PayloadAction<UpdateSubtiersOnboardingFormData>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtiersOnboardingFormData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtiersOnboardingFormDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateSubtiersOnboardingFormDataSuccess(response.data));
  }
}

function* handleFetchUpdateSubtierOnboardingTableRowRequest({
  payload,
}: PayloadAction<UpdateSubtierOnboardingTableRow>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSubtierOnboardingTableRow,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSubtierOnboardingTableRowFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateSubtierOnboardingTableRowSuccess({
        supplierId: payload.supplierId,
        data: response.data,
      })
    );
  }
}

function* handleFetchDesignAndBuiltDrawingsRequest({
  payload,
}: PayloadAction<FetchDesignAndBuiltDrawings>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchDesignAndBuiltDrawings,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchDesignAndBuiltDrawingsFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchDesignAndBuiltDrawingsSuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData,
      })
    );
  }
}

function* handleUpdateDesignAndBuiltDrawingRequest({
  payload,
}: PayloadAction<UpdateDesignAndBuildDrawing>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateDesignAndBuiltDrawing,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateDesignAndBuiltDrawingFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateDesignAndBuiltDrawingSuccess({
        drawingId: payload.drawingId,
        partOfRow: response.data,
      })
    );
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSpecsOfLeadTimeForProductionRequest({
  payload,
}: PayloadAction<FetchSpecsOfLeadTimeProductionInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfLeadTimeForProduction,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfLeadTimeForProductionFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfLeadTimeForProductionSuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData,
      })
    );
  }
}

function* handleUpdateSpecOfLeadTimeForProductionRequest({
  payload,
}: PayloadAction<UpdateSpecOfLeadTimeForProduction>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfLeadTimeForProduction,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfLeadTimeForProductionFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateSpecOfLeadTimeForProductionSuccess(response.data));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSpecsOfBufferStockStrategyRequest({
  payload,
}: PayloadAction<FetchSpecsOfBufferStockInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsOfBufferStockStrategy,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsOfBufferStockStrategyFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.fetchSpecsOfBufferStockStrategySuccess({
        response: response.data,
        isForFetchingToGetCountData: payload.isForFetchingToGetCountData,
      })
    );
  }
}

function* handleUpdateSpecOfBufferStockStrategyRequest({
  payload,
}: PayloadAction<UpdateSpecOfBufferStockStrategy>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSpecOfBufferStockStrategy,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateSpecOfBufferStockStrategyFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateSpecOfBufferStockStrategySuccess(response.data));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchSuppliersBySearchTextRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSuppliersBySearchText,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSuppliersFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSuppliersSuccess(response.data));
  }
}

function* handleFetchSuppliersByCompanyIdRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSuppliersByCompanyId,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSupplierByCompanyIdFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSupplierByCompanyIdSuccess(response.data));
  }
}

function* handleSendSupportMessageRequest({ payload }: PayloadAction<SendSupportMessageInput>) {
  const { response, error } = yield safeCall(vendorManagementService.sendSupportMessage, payload);

  if (error) {
    yield put(
      vendorManagementActions.sendSupportMessageFailure(
        new Error("Something went wrong, Please try again.")
      )
    );
    yield snackbarUtils.error("Something went wrong, Please try again.");
  } else {
    yield put(vendorManagementActions.sendSupportMessageSuccess());
    yield snackbarUtils.error("Support request successfully sent.");
    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* handleFetchIdentificationDataRequest({ payload }: PayloadAction<string>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchIdentificationData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchIdentificationDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchIdentificationDataSuccess(response.data));
  }
}

function* handleUpdateIdentificationDataRequest({
  payload,
}: PayloadAction<UpdateIdentificationDataBody>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateIdentificationData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateIdentificationDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateIdentificationDataSuccess(response.data));
    payload.onSuccess();
  }
}

// for validation management
function* handleFetchQuestionnairesRequest({
  payload,
}: PayloadAction<FetchQuestionnairesRequestInput>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchQuestionnairesRequest,
    payload
  );
  if (error) {
    yield put(
      vendorManagementActions.fetchQuestionnairesFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchQuestionnairesSuccess(response.data));
  }
}

function* handleFetchMessageThreadsOfStepRequest({
  payload,
}: PayloadAction<FetchMessageThreadsOfStepParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchMessageThreadsOfStepRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchMessageThreadsOfStepFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchMessageThreadsOfStepSuccess(response.data));
    if (payload?.onSuccess) {
      payload.onSuccess(response.data);
    }
  }
}

function* handleCreateNewMessageThreadRequest({
  payload,
}: PayloadAction<CreateNewMessageThreadParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.createNewMessageThreadRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.createNewMessageThreadFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.createNewMessageThreadSuccess({
        stepId: payload.stepId,
        messageThread: response.data,
      })
    );
    payload.onSuccess(response.data);
  }
}

function* handleCreateNewMessageWithinThreadRequest({
  payload,
}: PayloadAction<CreateNewMessageWithinThreadParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.createNewMessageWithinThreadRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.createNewMessageWithinThreadFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    if (payload.onSuccess) {
      payload.onSuccess(payload.messageThreadId);
    }
  }
}

function* handleFetchMessagesOfThreadRequest({
  payload,
}: PayloadAction<FetchMessagesOfThreadParam>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchMessagesOfThreadRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchMessagesOfThreadFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchMessagesOfThreadSuccess(response.data));
  }
}

function* handleUpdateMessageThreadRequest({ payload }: PayloadAction<UpdateThreadStatusParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateMessageThreadRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateMessageThreadFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateMessageThreadSuccess({
        thread: response.data,
        isResolved: payload.isResolved,
        stepId: payload.stepId,
      })
    );
    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleUpdateMessageWithinThreadRequest({
  payload,
}: PayloadAction<UpdateMessageWithinThreadParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateSingleMessageWithinThreadRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateMessageWithinThreadFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateMessageWithinThreadSuccess(response.data));
  }
}

function* handleUpdateApprovalStatusOfStepRequest({
  payload,
}: PayloadAction<UpdateApprovalStatusOfStepParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateApprovalStatusOfStepRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateApprovalStatusOfStepFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(
      vendorManagementActions.updateApprovalStatusOfStepSuccess({
        step: response.data,
        steps_to_be_approved: payload.steps_to_be_approved,
      })
    );
  }
}

function* handleUpdateApprovalStatusOfSpecRequest({
  payload,
}: PayloadAction<UpdateApprovalStatusOfSpecParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.updateApprovalStatusOfSpecRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.updateApprovalStatusOfSpecFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.updateApprovalStatusOfSpecSuccess(response.data));
    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  }
}

function* handleFetchNumberOfLinesApproved({
  payload,
}: PayloadAction<FetchNumberOfLinesApprovedParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchNumberOfUnapprovedLinesOfStep,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepSuccess(response.data));
  }
}

function* handleFetchStepsRequestWithoutCausingReRendering({
  payload,
}: PayloadAction<FetchStepsRequest>) {
  const { response, error } = yield safeCall(vendorManagementService.fetchSteps, payload);

  if (error) {
    return;
  } else {
    yield put(vendorManagementActions.fetchStepsSuccessWithoutCausingReRendering(response.data));
  }
}

// for readiness dashboard
function* handleFetchReadinessDashboardDataRequest({
  payload,
}: PayloadAction<FetchReadinessDashboardDataParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchReadinessDashboardData,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchReadinessDashboardDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchReadinessDashboardDataSuccess(response.data));
  }
}

function* handleFetchSuppliersTableDataRequest({
  payload,
}: PayloadAction<FetchSuppliersTableDataRequestParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSuppliersTableDataRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSuppliersTableDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSuppliersTableDataSuccess(response.data));
  }
}

function* handleFetchSpecsTableDataRequest({
  payload,
}: PayloadAction<FetchSpecsTableDataRequestParams>) {
  const { response, error } = yield safeCall(
    vendorManagementService.fetchSpecsTableDataRequest,
    payload
  );

  if (error) {
    yield put(
      vendorManagementActions.fetchSpecsTableDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchSpecsTableDataSuccess(response.data));
  }
}

function* handleFetchInternalNote({ payload }: PayloadAction<FetchInternalNoteRequestPayload>) {
  const { response, error } = yield safeCall(vendorManagementService.fetchInternalNote, payload);
  if (error) {
    yield put(
      vendorManagementActions.fetchInternalNoteFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(vendorManagementActions.fetchInternalNoteSuccess(response.data));
  }
}

function* handleUpdateInternalNote({ payload }: PayloadAction<UpdateInternalNoteRequestPayload>) {
  const _payload = { ...payload };
  delete _payload.onSuccess;
  const { response, error } = yield safeCall(vendorManagementService.updateInternalNote, _payload);

  if (error) {
    yield put(
      vendorManagementActions.updateInternalNoteFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    payload.onSuccess?.();
    yield put(vendorManagementActions.updateInternalNoteSuccess(response.data));
  }
}

function* handleExtractDataRequest() {
  const { response, error } = yield safeCall(vendorManagementService.extractDataRequest);

  if (error) {
    yield put(
      vendorManagementActions.extractDataFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    downloadFileNext(response.data, `VM_Analytics_${new Date().toISOString()}`, "xlsx");
    yield put(vendorManagementActions.extractDataSuccess());
  }
}

export function* vendorManagementSaga() {
  yield takeLatest(vendorManagementActions.fetchFormDataRequest, handleFetchFormDataRequest);

  yield takeLatest(vendorManagementActions.updateFormDataRequest, handleUpdateFormDataRequest);

  yield takeLatest(vendorManagementActions.fetchStepsRequest, handleFetchStepsRequest);

  yield takeEvery(vendorManagementActions.updateStepRequest, handleUpdateStepRequest);

  yield takeLatest(
    vendorManagementActions.fetchProcessControlStandardsRequest,
    handleProcessControlStandardsRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchAllSpecificationsRequest,
    handleFetchAllSpecificationsRequest
  );

  yield takeLatest(
    vendorManagementActions.deleteProcessControlStandardRequest,
    handleDeleteProcessControlStandardRequest
  );

  yield takeLatest(
    vendorManagementActions.addProcessControlStandardRequest,
    handleAddProcessControlStandardRequest
  );

  yield takeLatest(
    vendorManagementActions.updateProcessControlStandardRequest,
    handleUpdateProcessControlStandardRequest
  );

  yield takeEvery(
    vendorManagementActions.updateApprovalFormFileRequest,
    handleUpdateApprovalFormFileRequest
  );

  yield takeEvery(
    vendorManagementActions.deleteApprovalFormFileRequest,
    handleDeleteApprovalFormFileRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchAclpClassificationsRequest,
    handleFetchAclpClassificationsRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSupplierCategoriesRequest,
    handleFetchSupplierCategoriesRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfScopeRequest,
    handleFetchSpecsOfScopeRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchRemovedSpecsOfScopeRequest,
    handleFetchRemovedSpecsOfScope
  );

  yield takeLatest(
    vendorManagementActions.fetchAddedSpecsOfScopeRequest,
    handleFetchAddedSpecsOfScope
  );

  yield takeEvery(
    vendorManagementActions.removeSpecFromScopeRequest,
    handleRemoveSpecFromScopeRequest
  );

  yield takeLatest(
    vendorManagementActions.saveAdditionalCommentFieldOfFormRequest,
    handleSaveAdditionalCommentFieldOfFormRequest
  );

  yield takeEvery(vendorManagementActions.addSpecToScopeRequest, handleAddSpecToScopeRequest);

  yield takeEvery(vendorManagementActions.saveSpecOfScopeRequest, handleSaveSpecOfScopeRequest);

  yield takeLatest(
    vendorManagementActions.updateLeadTimeImplFormDataRequest,
    handleUpdateLeadTimeImplFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtierFormDataRequest,
    handleUpdateSubtiersFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSubtierCompaniesRequest,
    handleFetchSubtierCompaniesRequest
  );

  yield takeEvery(
    vendorManagementActions.fetchSpecsOfSubtierSupplierRequest,
    handleFetchSpecsOfSubtierSupplierRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfSubtierSupplierRequest,
    handleUpdateSpecOfSubtierSupplierRequest
  );

  yield takeLatest(
    vendorManagementActions.deleteSpecOfSubtierSupplierRequest,
    handleDeleteSpecOfSubtierSupplierRequest
  );

  yield takeEvery(
    vendorManagementActions.addSpecToSubtierSupplierRequest,
    handleAddSpecToSubtierSupplierRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSubtierOnboardingTableDataRequest,
    handleFetchSubtierOnboardingTableDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtiersOnboardingFormDataRequest,
    handleUpdateSubtiersOnboardingFormDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateSubtierOnboardingTableRowRequest,
    handleFetchUpdateSubtierOnboardingTableRowRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchDesignAndBuiltDrawingsRequest,
    handleFetchDesignAndBuiltDrawingsRequest
  );

  yield takeLatest(
    vendorManagementActions.updateDesignAndBuiltDrawingRequest,
    handleUpdateDesignAndBuiltDrawingRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfLeadTimeForProductionRequest,
    handleFetchSpecsOfLeadTimeForProductionRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfLeadTimeForProductionRequest,
    handleUpdateSpecOfLeadTimeForProductionRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsOfBufferStockStrategyRequest,
    handleFetchSpecsOfBufferStockStrategyRequest
  );

  yield takeEvery(
    vendorManagementActions.updateSpecOfBufferStockStrategyRequest,
    handleUpdateSpecOfBufferStockStrategyRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSuppliersRequest,
    handleFetchSuppliersBySearchTextRequest
  );
  yield takeLatest(
    vendorManagementActions.fetchSupplierByCompanyIdRequest,
    handleFetchSuppliersByCompanyIdRequest
  );
  yield takeLatest(
    vendorManagementActions.sendSupportMessageRequest,
    handleSendSupportMessageRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchIdentificationDataRequest,
    handleFetchIdentificationDataRequest
  );

  yield takeLatest(
    vendorManagementActions.updateIdentificationDataRequest,
    handleUpdateIdentificationDataRequest
  );

  // for validation management
  yield takeLatest(
    vendorManagementActions.fetchQuestionnairesRequest,
    handleFetchQuestionnairesRequest
  );

  yield takeEvery(
    vendorManagementActions.fetchMessageThreadsOfStepRequest,
    handleFetchMessageThreadsOfStepRequest
  );

  yield takeLatest(
    vendorManagementActions.createNewMessageThreadRequest,
    handleCreateNewMessageThreadRequest
  );

  yield takeLatest(
    vendorManagementActions.createNewMessageWithinThreadRequest,
    handleCreateNewMessageWithinThreadRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchMessagesOfThreadRequest,
    handleFetchMessagesOfThreadRequest
  );

  yield takeLatest(
    vendorManagementActions.updateMessageThreadRequest,
    handleUpdateMessageThreadRequest
  );

  yield takeLatest(
    vendorManagementActions.updateMessageWithinThreadRequest,
    handleUpdateMessageWithinThreadRequest
  );

  yield takeLatest(
    vendorManagementActions.updateApprovalStatusOfStepRequest,
    handleUpdateApprovalStatusOfStepRequest
  );

  yield takeLatest(
    vendorManagementActions.updateApprovalStatusOfSpecRequest,
    handleUpdateApprovalStatusOfSpecRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest,
    handleFetchNumberOfLinesApproved
  );

  yield takeLatest(
    vendorManagementActions.fetchStepsRequestWithoutCausingReRendering,
    handleFetchStepsRequestWithoutCausingReRendering
  );

  // for readiness dashboard
  yield takeLatest(
    vendorManagementActions.fetchReadinessDashboardDataRequest,
    handleFetchReadinessDashboardDataRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSuppliersTableDataRequest,
    handleFetchSuppliersTableDataRequest
  );

  yield takeLatest(
    vendorManagementActions.fetchSpecsTableDataRequest,
    handleFetchSpecsTableDataRequest
  );

  yield takeLatest(vendorManagementActions.fetchInternalNote, handleFetchInternalNote);

  yield takeLatest(vendorManagementActions.updateInternalNote, handleUpdateInternalNote);

  yield takeLatest(vendorManagementActions.extractDataRequest, handleExtractDataRequest);
}
