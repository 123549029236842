import { QuestionnaireType } from "../redux";

export const getPlatformGuidlines = (questionnaire: QuestionnaireType) => {
  switch (questionnaire) {
    case QuestionnaireType.pomDistributorQuestionnaire:
      return {
        url: "https://questionnaires-upload.s3.ca-central-1.amazonaws.com/How+to_A2X+Questionnaires+Distributor+(Suppliers+version).pptx",
        name: "How to _ A2X Questionnaires Distributor (Suppliers version).pptx",
      };
    case QuestionnaireType.pomManufacturerQuestionnaire:
      return {
        url: "https://questionnaires-upload.s3.ca-central-1.amazonaws.com/How+to+_+A2X+Questionnaires+MANUFACTURER+(Suppliers+version).pptx",
        name: "How to _ A2X Questionnaires MANUFACTURER (Suppliers version).pptx",
      };
    case QuestionnaireType.posEndUsersQuestionnaire:
      return {
        url: "https://questionnaires-upload.s3.ca-central-1.amazonaws.com/How+to+_+A2X+Questionnaires+END+USER+(Suppliers+version).pptx",
        name: "How to _ A2X Questionnaires END USER (Suppliers version).pptx",
      };
  }
};
