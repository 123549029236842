import React from "react";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box } from "@mui/material";
import { HeaderCell } from "../table-cells/header-cell";
import { ValidationCell } from "../table-cells/validation-cell";
import { SelectSupplierAutoComplete } from "../../select-supplier-auto-complete/select-supplier-auto-complete";
import { SpecCell } from "../table-cells/spec-cell";
import { RemoveBtnCell } from "../table-cells/remove-btn-cell";

type ExtraProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  isDisabled: boolean;
};

const SourceOfSupplierCell: React.FC<GridRenderCellParams & ExtraProps> = ({
  row,
  searchValue,
  setSearchValue,
  isDisabled,
}) => {
  return (
    <Box width="100%">
      {!isDisabled ? (
        <SelectSupplierAutoComplete
          row={row}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      ) : (
        row?.source_of_supply?.map((supplier: any) => supplier?.title)
      )}
    </Box>
  );
};

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getScopeFormTableColumns = (
  isTableInPomDistributorQuestionnaire: boolean,
  isFormDisplayForValidationManagement: boolean,
  isTableDisabled: boolean,
  searchValue: string,
  setSearchValue: (searchValue: string) => void
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      sortable: true,
      field: "name",
      headerName: "Specification",
      renderCell: (params) =>
        !isFormDisplayForValidationManagement ? (
          <SpecCell {...params} text={params?.row?.name} />
        ) : undefined,
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
  ];

  const lastColumn = !isFormDisplayForValidationManagement
    ? {
        ...defaultColumnConfig,
        field: "removeRowButton",
        headerName: " ",
        align: "center" as const,
        renderCell: (params: GridRenderCellParams) => {
          return <RemoveBtnCell disabled={isTableDisabled} {...params} />;
        },
        flex: 0.1,
      }
    : {
        ...defaultColumnConfig,
        field: "action",
        headerName: "Action",
        renderCell: (params: GridRenderCellParams) => {
          return <ValidationCell params={params} />;
        },
        minWidth: 180,
      };

  const columnsForPomDistributor = [
    {
      ...defaultColumnConfig,
      field: "source_of_supply",
      headerName: "Source of supply",
      minWidth: 470,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => {
        const isDisabled =
          params?.row?.approval_status === "approved" || isFormDisplayForValidationManagement;
        return (
          <SourceOfSupplierCell
            {...params}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isDisabled={isDisabled}
          />
        );
      },
    },
    {
      ...defaultColumnConfig,
      field: "manufacturing_site",
      headerName: "Manufacturing site",
    },
    { ...lastColumn },
  ];

  if (isTableInPomDistributorQuestionnaire) {
    columns.push(...columnsForPomDistributor);
  } else {
    columns.push(lastColumn);
  }

  return columns;
};
