import React, { useEffect, useMemo } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, useMediaQuery } from "@mui/material";
import NextAxyaLogo from "assets/img/axya-logo-topbar.svg";
import { AppBarNextItems } from "./app-bar-next-action-items/app-bar-next-action-items";
import { getCompany, selectCompayStats } from "services/profile/profile.selectors";
import { useDispatch, useSelector } from "react-redux";
import { AppBarNextDrawer } from "./app-bar-next-drawer";
import { AppBarNextDropdown } from "./app-bar-next-dropdown";
import { modalsActions } from "services/modals/modals.actions";
import { useHomePageLink } from "@next/hooks/useHomePageLink";
import { Link } from "react-router-dom";
import {
  useBuyerAppBarItems,
  useFreemiumBuyerAppBarItems,
  useSellerAppBarItems,
} from "./app-bar-next.definitions";
import { CompanyTypeNext } from "@next/redux/types";
import { checkIsSellerCompany } from "@next/utils/checkCompanyUtils";
import i18n from "assets/configi18n/i18n";
import { AppBarNextCompanyLogo } from "./app-bar-next-company-logo";
import { UserProfile } from "@next/modules/profile/components/user-profile/user-profile";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectMarketplaceCounts } from "@next/modules/workspace/redux/selectors";
import { useCompanyPlan } from "@next/hooks/useIsPremiumCompany";
import { createStyles, makeStyles } from "@mui/styles";
import { getErpConfiguration } from "services/part-configuration/part-configuration.selectors";
import { AppBarNextErpConfiguration } from "./app-bar-next-erp-configuration";
import { AppBarNextPinnedPages } from "./app-bar-next-pinned-pages";

type StyleProps = {
  isHideNav?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    appBarRoot: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.appbar.main,
      color: theme.palette.background.default,
      boxShadow: "none",
      zIndex: 1199,
    },
    logo: {
      width: "78px",
      height: "24px",
    },
    toolbarRoot: {
      paddingLeft: "16px",
      width: "100%",
      justifyContent: ({ isHideNav }) => (isHideNav ? "flex-end" : "default"),
    },
    itemsContainer: {
      flexGrow: 1,
      display: "flex",
      alignItem: "center",
      marginLeft: "16px",
      width: "50%",
      overflow: "auto",
    },
  })
);

type Props = {
  isHideNav?: boolean;
};

const AppBarNext: React.FC<Props> = ({ isHideNav }) => {
  const classes = useStyles({ isHideNav });
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const homePageLink = useHomePageLink();
  const company = useSelector(getCompany);
  const isSeller = checkIsSellerCompany(company?.type as CompanyTypeNext);
  const counts = useSelector(selectMarketplaceCounts);
  const companyStats = useSelector(selectCompayStats);
  const erpConfiguration = useSelector(getErpConfiguration);
  const isFreemiumBuyer = useCompanyPlan("freemium");
  const buyerAppBarItems = useBuyerAppBarItems();
  const freemiumBuyerAppBarItems = useFreemiumBuyerAppBarItems();
  const sellerAppBarItems = useSellerAppBarItems();

  const appBarItems = useMemo(() => {
    if (isSeller) {
      return sellerAppBarItems;
    } else {
      if (isFreemiumBuyer) {
        return freemiumBuyerAppBarItems;
      }

      return buyerAppBarItems;
    }
  }, [
    isSeller,
    i18n.language,
    counts,
    companyStats,
    isFreemiumBuyer,
    buyerAppBarItems,
    freemiumBuyerAppBarItems,
    sellerAppBarItems,
  ]);

  useEffect(() => {
    const isModalOpen = localStorage.getItem("isAxyaAnnouncementVisited");

    if (!isModalOpen && company?.type !== CompanyTypeNext.DO) {
      dispatch(modalsActions.showModal(true, "isAxyaAnnouncementModalOpen"));
    }
  }, []);

  useEffect(() => {
    if (isSeller) {
      dispatch(workspaceNextActions.fetchMarketplaceCountsRequest());
    }
  }, [isSeller]);

  if (!company?.type) return null;

  return (
    <AppBar position="sticky" className={classes.appBarRoot}>
      <Toolbar className={classes.toolbarRoot}>
        {!isHideNav ? (
          <>
            <Link to={homePageLink}>
              <img src={NextAxyaLogo} alt="Axya" className={classes.logo} />
            </Link>

            <Box className={classes.itemsContainer}>
              {isMobile ? (
                <AppBarNextDrawer appBarItems={appBarItems} />
              ) : (
                <>
                  <AppBarNextDropdown appBarItems={appBarItems} />
                  <AppBarNextPinnedPages appBarItems={appBarItems} />
                </>
              )}
            </Box>
          </>
        ) : null}

        {!isMobile ? <AppBarNextItems /> : null}

        {!!erpConfiguration && (
          <Box ml="8px" mr="16px">
            <AppBarNextErpConfiguration />
          </Box>
        )}

        <Box ml="8px" mr="16px">
          <AppBarNextCompanyLogo />
        </Box>

        <UserProfile />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarNext;
