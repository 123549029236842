import React from "react";
import { useSelector } from "react-redux";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./readiness-dashboard-overview-scope-suppliers-tiles.styled";

export const ReadinessDashboardOverviewScopeSuppliersTiles: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);

  return (
    <S.StyledContainer>
      <S.StyledBox>
        <S.StyledTypography variant="h6">{"In Scope Suppliers"}</S.StyledTypography>
        <S.StyledTypography fontFamily={"Space Grotesk"} variant="h4">
          {dashboardData?.overview?.suppliers_count?.in_scope_count || 0}
        </S.StyledTypography>
      </S.StyledBox>
      <S.StyledBox>
        <S.StyledTypography variant="h6">{"Out Of Scope Suppliers"}</S.StyledTypography>
        <S.StyledTypography fontFamily={"Space Grotesk"} variant="h4">
          {dashboardData?.overview?.suppliers_count?.out_of_scope_count || 0}
        </S.StyledTypography>
      </S.StyledBox>
    </S.StyledContainer>
  );
};
