import axios from "axios";
import { getToken } from "services/profile/profile.selectors";
import { store } from "store";
import { downloadFileNext } from "./fileUtils";

export const fileDownloadFromUrl = (
  url: string,
  fileName?: string,
  fileExtension?: string,
  requiresToken: boolean = false,
  propagateError: boolean = false,
  onFinish?: () => void,
  getFileNameFromContentDisposition: boolean = true
) => {
  return axios
    .get(url, {
      responseType: "blob",
      headers: requiresToken
        ? {
            Authorization: `JWT ${getToken(store.getState())}`,
          }
        : {},
    })
    .then((res) => {
      const contentDisposition = res.headers["content-disposition"];
      let extractedFileName = fileName || `download_${Date.now()}`;

      if (
        contentDisposition &&
        contentDisposition.includes("filename=") &&
        getFileNameFromContentDisposition
      ) {
        extractedFileName = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .trim()
          .replace(/"/g, "");
        extractedFileName = decodeURIComponent(extractedFileName);
      }

      downloadFileNext(res.data, extractedFileName, fileExtension, onFinish);
    })
    .catch((error) => {
      if (propagateError) throw error;
      else console.log("File download error", error);
    });
};

export const newWindowFileDownloadFromUrl = (fileUrl: string, fileName: string = fileUrl) => {
  const newTab = window.open(fileUrl, "_blank");

  if (newTab) {
    // If the tab is successfully opened, try downloading the file
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName; // specify the name of the file to be saved
    a.click(); // Trigger the download
    newTab.close(); // Close the new tab after triggering download
  } else {
    // If the new tab couldn't be opened, log an error or handle as needed
    console.error("Unable to open new tab for file download");
  }
};
