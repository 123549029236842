import moment from "moment";
import { RootState } from "store";
import { QALatestSeenStatusContent } from "./types";
import { RFQSupplierType } from "../components/rfq-creation-form/rfq-creation.form.types";

export const selectQATopics = (state: RootState) => state.workspaceNext.qaTopics;
export const selectQATopicsLoading = (state: RootState) => state.workspaceNext.qaTopicsLoading;

export const selectQATopic = (state: RootState) => state.workspaceNext.qaTopic;
export const selectQATopicLoading = (state: RootState) => state.workspaceNext.qaTopicLoading;

export const getSelectQALatestMessageSeenBySupplier =
  (supplier: RFQSupplierType, viewsDates: string[] = []) =>
  (state: RootState): QALatestSeenStatusContent | null => {
    const latestViewDate = viewsDates.sort((a, b) => (moment(a).isAfter(b) ? -1 : 1))[0];
    const messages =
      state.workspaceNext.qaTopic?.messages || state.workspaceNext.qaThreadDetails?.messages;
    if (supplier && latestViewDate && messages) {
      if (messages.length > 0) {
        const { created_at: latestMessageCreationDate, created_by: latestMessageCreatedBy } =
          messages[messages.length - 1];
        const isSupplierUserMessage = latestMessageCreatedBy.company !== supplier.id;
        if (
          isSupplierUserMessage &&
          latestMessageCreationDate &&
          moment(latestViewDate).isAfter(latestMessageCreationDate)
        ) {
          return { supplier, seenAt: latestViewDate };
        }
      }
    }
    return null;
  };

export const selectQALatestPOMessageSeenBySupplier = (
  state: RootState
): QALatestSeenStatusContent | null => {
  const winnerSupplier = state.workspaceNext.qaTopic?.rfq?.winner;
  const messages = state.workspaceNext.qaTopic?.messages;
  const currentOrder = state.ordersNext.order;

  if (winnerSupplier && messages && currentOrder) {
    const orderViewsDates = Object.values(currentOrder.views || {});
    if (messages.length > 0 && orderViewsDates.length > 0) {
      return getSelectQALatestMessageSeenBySupplier(winnerSupplier, orderViewsDates)(state);
    }
  }

  return null;
};

export const selectInternalChatTopic = (state: RootState) => state.workspaceNext.internalChatTopic;
export const selectInternalChatTopicLoading = (state: RootState) =>
  state.workspaceNext.internalChatTopicLoading;

export const selectCreateQATopicLoading = (state: RootState) =>
  state.workspaceNext.createQaTopicLoading;

export const selectAnswerQATopicLoading = (state: RootState) =>
  state.workspaceNext.answerQaTopicLoading;

export const selectContactUsLoading = (state: RootState) => state.workspaceNext.contactUsLoading;
export const selectEditRfqDescription = (state: RootState) =>
  state.workspaceNext.isEditRfqDescription;
export const toggleShowQuotationVariance = (state: RootState) =>
  state.workspaceNext.showQuotationVariance;
export const selectNDAList = (state: RootState) => state.workspaceNext.ndaList;
export const selectNDAListLoading = (state: RootState) => state.workspaceNext.ndaListLoading;

export const selectRfqFormPartsList = (state: RootState) => state.workspaceNext.rfqForm.partsList;
export const selectRfqFormPartsListNext = (state: RootState) =>
  state.workspaceNext.rfqForm.partsListNext;
export const selectRfqFormPartsListLoading = (state: RootState) =>
  state.workspaceNext.rfqForm.fetchPartsLoading;

export const selectRfqFormFavoriteSuppliersList = (state: RootState) =>
  state.workspaceNext.rfqForm.favoriteSuppliersList;
export const selectRfqFormFavoriteSuppliersListCount = (state: RootState) =>
  state.workspaceNext.rfqForm.favoriteSuppliersListCount;
export const selectRfqFormFavoriteSuppliersListNext = (state: RootState) =>
  state.workspaceNext.rfqForm.favoriteSuppliersListNext;
export const selectRfqFormFavoriteSuppliersListLoading = (state: RootState) =>
  state.workspaceNext.rfqForm.fetchFavoriteSuppliersLoading;

export const selectRfqFormAdditionalFiles = (state: RootState) =>
  state.workspaceNext.rfqForm.additionalFiles;

export const selectFavoriteSuppliers = (state: RootState) => state.workspaceNext.favoriteSuppliers;
export const selectFavoriteSuppliersLoading = (state: RootState) =>
  state.workspaceNext.favoriteSuppliersLoading;
export const selectFavoriteSuppliersCount = (state: RootState) =>
  state.workspaceNext.favoriteSuppliersCount;

export const selectFavoriteSupplierGroups = (state: RootState) =>
  state.workspaceNext.favoriteSupplierGroups;
export const selectFavoriteSupplierGroupsLoading = (state: RootState) =>
  state.workspaceNext.favoriteSupplierGroupsLoading;
export const selectFileUploadProgress = (state: RootState) =>
  state.workspaceNext.fileUploadProgress;
export const selectFileUploading = (state: RootState) => state.workspaceNext.fileUploading;
export const selectAddFavoriteSupplierLoading = (state: RootState) =>
  state.workspaceNext.addFavoriteSupplierLoading;
export const selectRemoveFavoriteSupplierLoading = (state: RootState) =>
  state.workspaceNext.removeFavoriteSupplierLoading;

export const selectCreateFavoriteSupplierGroupLoading = (state: RootState) =>
  state.workspaceNext.createFavoriteSupplierGroupLoading;

export const selectContracts = (state: RootState) => state.workspaceNext.contracts;
export const selectContractsLoading = (state: RootState) => state.workspaceNext.contractsLoading;
export const selectContractsCount = (state: RootState) => state.workspaceNext.contractsCount;
export const selectContractsLastQuery = (state: RootState) =>
  state.workspaceNext.contractsLastQuery;

export const selectSaveDraftRFQLoading = (state: RootState) =>
  state.workspaceNext.saveDraftRFQLoading;
export const selectFetchDraftRFQsLoading = (state: RootState) =>
  state.workspaceNext.draftRFQsLoading;
export const selectActiveDraftRFQ = (state: RootState) => state.workspaceNext.activeDraftRFQ;
export const selectDraftRFQsCount = (state: RootState) => state.workspaceNext.draftRFQsCount;
export const selectDraftRFQs = (state: RootState) => state.workspaceNext.draftRFQs;
export const selectDeleteDraftRFQLoading = (state: RootState) =>
  state.workspaceNext.deleteDraftRFQLoading;
export const selectLoadDraftRFQLoading = (state: RootState) =>
  state.workspaceNext.loadDraftRFQLoading;
export const selectUpdateDraftRFQCollaboratorsLoading = (state: RootState) =>
  state.workspaceNext.updateDraftRFQCollaboratorsLoading;

export const selectMyParts = (state: RootState) => state.workspaceNext.myParts;
export const selectMyPartsLoading = (state: RootState) => state.workspaceNext.myPartsLoading;
export const selectMyPartsCount = (state: RootState) => state.workspaceNext.myPartsCount;
export const selectMyPartsLastQuery = (state: RootState) => state.workspaceNext.myPartsLastQuery;

export const selectMyPartsExportLoading = (state: RootState) =>
  state.workspaceNext.exportMyPartsLoading;
export const selectMyPartsImportLoading = (state: RootState) =>
  state.workspaceNext.importMyPartsLoading;
export const selectMyPartsImportSuccess = (state: RootState) =>
  state.workspaceNext.importMyPartsSuccess;

export const selectUpdatePartLoading = (state: RootState) => state.workspaceNext.updatePartLoading;

export const selectMyPart = (pk: number) => (state: RootState) =>
  state.workspaceNext.myParts.find((item) => item?.pk === pk);

export const selectUploadedMyPartAttachments = (state: RootState) =>
  state.workspaceNext.uploadMyPartsAttachments;
export const selectUploadedMyPartAttachmentsLoading = (state: RootState) =>
  state.workspaceNext.uploadMyPartsAttachmentsLoading;

export const getSelectContractMarkAsCompleteLoading = (pk: number) => (state: RootState) =>
  state.workspaceNext.contractMarkAsCompleteLoading[pk];

export const getSelectExportBRPFilesLoading = (rfqId: string) => (state: RootState) =>
  state.workspaceNext.exportBRPFilesLoading[rfqId];

export const selectFetchSupplierContactsListLoading = (state: RootState) =>
  state.workspaceNext.fetchSupplierContactsListLoading;
export const selectSupplierContactsList = (state: RootState) =>
  state.workspaceNext.supplierContactsList;

export const selectInviteSupplierContactLoading = (state: RootState) =>
  state.workspaceNext.inviteSupplierContactLoading;

export const selectUpdateSupplierContactsListLoading = (state: RootState) =>
  state.workspaceNext.updateSupplierContactsListLoading;

export const selectTendersData = (state: RootState) => state.workspaceNext.tenders;
export const selectTendersLoading = (state: RootState) => state.workspaceNext.tendersLoading;

export const selectAxyaRFQStatsData = (state: RootState) => state.workspaceNext.axyaStatsSupplier;
export const selectAxyaRFQStatsLoading = (state: RootState) =>
  state.workspaceNext.axyaStatsSupplierLoading;

export const selectPortalData = (state: RootState) => state.workspaceNext.portalData;

export const selectPortalDataLoading = (state: RootState) => state.workspaceNext.portalDataLoading;

export const selectUpdateRFQCollaboratorsLoading = (state: RootState) =>
  state.workspaceNext.updateRFQCollaboratorsLoading;

export const selectUpdateRFQFollowupDateLoading = (state: RootState) =>
  state.workspaceNext.updateRFQFollowupDateLoading;

export const selectUpdatePortalQuoteNoQuoteLoading = (state: RootState) =>
  state.workspaceNext.updatePortalQuoteNoQuoteLoading;

export const selectUploadPortalQuoteFilesLoading = (state: RootState) =>
  state.workspaceNext.uploadPortalQuoteFilesLoading;

export const selectCreatePortalQuoteLoading = (state: RootState) =>
  state.workspaceNext.createPortalQuoteLoading;

export const selectUpdatePortalQuoteLoading = (state: RootState) =>
  state.workspaceNext.updatePortalQuoteLoading;

export const selectSendRevisionLoading = (state: RootState) =>
  state.workspaceNext.sendRevisionLoading;

export const selectSendRevisionWithNoteLoading = (state: RootState) =>
  state.workspaceNext.sendRevisionWithNoteLoading;

export const selectRevisionSendInProgress = (state: RootState) =>
  state.workspaceNext.revisionSendInProgress;

export const selectShowRevisionStatusBar = (state: RootState) =>
  state.workspaceNext.showRevisionStatusBar;

export const selectGlobalSearchLoading = (state: RootState) =>
  state.workspaceNext.globalSearchLoading;

export const selectGlobalSearchResults = (state: RootState) =>
  state.workspaceNext.globalSearchResults;

export const selectGlobalSearchNotFound = (state: RootState) =>
  state.workspaceNext.globalSearchNotFound;

export const selectSupplierQuotes = (state: RootState) => state.workspaceNext.supplierQuotes;
export const selectSupplierQuotesError = (state: RootState) =>
  state.workspaceNext.supplierQuotesError;
export const selectSupplierQuotesLoading = (state: RootState) =>
  state.workspaceNext.supplierQuotesLoading;
export const selectSupplierQuotesCounts = (state: RootState) =>
  state.workspaceNext.supplierQuotesCounts;

export const selectMarketplaceCounts = (state: RootState) => state.workspaceNext.marketplaceCounts;
export const selectMarketplaceCountsLoading = (state: RootState) =>
  state.workspaceNext.marketplaceCountsLoading;
export const selectUploadRfqAttachmentsLoading = (state: RootState) =>
  state.workspaceNext.uploadRfqAttachmentsLoading;

export const selectPublicQALastToken = (state: RootState) => state.workspaceNext.qaTopicLastToken;

export const selectPresignedS3Url = (state: RootState) => state.workspaceNext.presignedS3Url;
export const selectPresignedS3UrlError = (state: RootState) =>
  state.workspaceNext.presignedS3UrlError;

export const selectUploadS3FileLoading = (state: RootState) =>
  state.workspaceNext.uploadS3FileLoading;
export const selectUploadS3FileError = (state: RootState) => state.workspaceNext.uploadS3FileError;

export const selectAddAddendaLoading = (state: RootState) => state.workspaceNext.addAddendaLoading;

export const selectQuoteComparison = (state: RootState) => state.workspaceNext.quoteComparison;
export const selectQuoteComparisonLoading = (state: RootState) =>
  state.workspaceNext.quoteComparisonLoading;

export const selectUpdateQuoteComparisonConfigLoading = (state: RootState) =>
  state.workspaceNext.updateQuoteComparisonConfigLoading;

export const selectSendNewVerificationEmailLoading = (state: RootState) =>
  state.workspaceNext.sendNewVerificationEmailLoading;

export const selectCheckEmailExistsLoading = (state: RootState) =>
  state.workspaceNext.checkEmailAlreadyExistsLoading;

export const selectQuoteComparisonLastQueryRfqId = (state: RootState) =>
  state.workspaceNext.quoteComparisonLastQueryRfqId;

export const selectProceedQuoteComparisonSelectionLoading = (state: RootState) =>
  state.workspaceNext.proceedQuoteComparisonSelectionLoading;

export const selectCreateEasyRfqLoading = (state: RootState) =>
  state.workspaceNext.createEasyRfqLoading;

export const selectUpdateRFQTargetProfilesLoading = (state: RootState) =>
  state.workspaceNext.updateRFQTargetedProfilesLoading;

export const selectUploadMultiplePartFiles = (state: RootState) =>
  state.workspaceNext.uploadMultiplePartFilesLoading;

export const selectSyncERPQuotationLoading = (state: RootState) =>
  state.workspaceNext.syncERPQuotationLoading;

export const selectRfqQuestionsStats = (state: RootState) => state.workspaceNext.rfqQuestionsStats;

export const selectRfqQuestionsStatsLoading = (state: RootState) =>
  state.workspaceNext.rfqQuestionsStatsLoading;

export const selectQAThreadDetails = (state: RootState) => state.workspaceNext.qaThreadDetails;

export const selectQAThreadDetailsLoading = (state: RootState) =>
  state.workspaceNext.qaThreadDetailsLoading;

export const selectSendEmailConfirmationLoading = (state: RootState) =>
  state.workspaceNext.sendEmailConfirmationLoading;

export const selectUploadQuotationAsBuyerLoading = (state: RootState) =>
  state.workspaceNext.uploadQuotationAsBuyerLoading;

export const selectUploadQuotationFilesAsBuyerLoading = (state: RootState) =>
  state.workspaceNext.uploadQuotationFilesAsBuyerLoading;

export const selectInternalNotesList = (state: RootState) => state.workspaceNext.internalNotes;

export const selectInternalNotesMutateLoading = (state: RootState) =>
  state.workspaceNext.internalNotesMutateLoading;

export const selectUpdateInternalNoteDescLoading = (state: RootState) =>
  state.workspaceNext.updateInternalNoteDescLoading;
export const selectSearchSuppliersResults = (state: RootState) =>
  state.workspaceNext.searchSuppliers;

export const selectSearchSuppliersType = (state: RootState) =>
  state.workspaceNext.searchSuppliers.type;

export const selectSearchSuppliersLoading = (state: RootState) =>
  state.workspaceNext.searchSuppliersLoading;

export const selectSearchSuppliersError = (state: RootState) =>
  state.workspaceNext.searchSuppliersError;

export const selectAddASLLoading = (state: RootState) => state.workspaceNext.addASLLoading;

export const selectAddASLError = (state: RootState) => state.workspaceNext.addASLError;

export const selectSendAirbusConfirmationLoading = (state: RootState) =>
  state.workspaceNext.sendAirbusConfirmationLoading;
export const selectAssignInternalNoteLoading = (state: RootState) =>
  state.workspaceNext.assignInternalNoteLoading;
export const selectfetchOTT = (state: RootState) => state.workspaceNext.OneTimeToken;
export const selectOttLoading = (state: RootState) => state.workspaceNext.fetchOTTRequestLoading;

export const workspaceNextSelectors = {
  selectUploadMultiplePartFiles,
  selectQATopics,
  selectQATopicsLoading,
  selectQATopic,
  selectQATopicLoading,
  selectInternalChatTopic,
  selectInternalChatTopicLoading,
  selectCreateQATopicLoading,
  selectAnswerQATopicLoading,
  selectContactUsLoading,
  selectNDAList,
  selectNDAListLoading,
  selectFavoriteSuppliers,
  selectFavoriteSuppliersLoading,
  selectFavoriteSuppliersCount,
  selectCreateFavoriteSupplierGroupLoading,
  selectContracts,
  selectContractsLoading,
  selectContractsCount,
  selectContractsLastQuery,
  selectMyParts,
  selectMyPartsLoading,
  selectMyPartsCount,
  selectMyPartsLastQuery,
  selectMyPartsExportLoading,
  selectMyPartsImportLoading,
  selectMyPartsImportSuccess,
  selectUpdatePartLoading,
  selectMyPart,
  selectPresignedS3Url,
  selectPresignedS3UrlError,
  selectUploadedMyPartAttachments,
  selectPortalData,
  selectPortalDataLoading,
  selectUploadS3FileError,
  selectUploadS3FileLoading,
  selectUpdatePortalQuoteNoQuoteLoading,
  selectUploadPortalQuoteFilesLoading,
  selectCreatePortalQuoteLoading,
  selectUpdatePortalQuoteLoading,
  selectSupplierQuotes,
  selectSupplierQuotesError,
  selectSupplierQuotesLoading,
  selectSupplierQuotesCounts,
  selectPublicQALastToken,
  selectUploadRfqAttachmentsLoading,
  selectAddAddendaLoading,
  selectQuoteComparison,
  selectQuoteComparisonLoading,
  selectSendNewVerificationEmailLoading,
  selectUpdateQuoteComparisonConfigLoading,
  selectQuoteComparisonLastQueryRfqId,
  selectProceedQuoteComparisonSelectionLoading,
  selectCreateEasyRfqLoading,
  selectUpdateRFQTargetProfilesLoading,
  selectSyncERPQuotationLoading,
  selectRfqQuestionsStats,
  selectRfqQuestionsStatsLoading,
  selectUploadQuotationAsBuyerLoading,
  selectUploadQuotationFilesAsBuyerLoading,
  selectInternalNotesList,
  selectInternalNotesMutateLoading,
  selectSendAirbusConfirmationLoading,
  selectAssignInternalNoteLoading,
};
