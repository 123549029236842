import React, { useCallback } from "react";

import { batch, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import { workspaceActions } from "services/workspace";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { getPlainAndRichTextAsString } from "@next/components/rich-text-editor/rich-text-editor-utils";
import { RfqDescriptionFormBase, RfqDescriptionFormValue } from "./rfq-description-form.base";
import { Box } from "@mui/material";
import { selectEditRfqDescription } from "@next/modules/workspace/redux/selectors";

interface RfqDescriptionProps {
  pk: number;
  additionalDetailsRichText?: string | Record<string, any>;
  additionalDetails?: string;
}

const RfqDescription: React.FC<RfqDescriptionProps> = ({
  pk,
  additionalDetailsRichText,
  additionalDetails,
}) => {
  const dispatch = useDispatch();
  const isEditRfqDescription = useSelector(selectEditRfqDescription);

  const initialValues: RfqDescriptionFormValue = {
    additionalDetails: additionalDetailsRichText,
  };

  const handleRfqUpdatePartial = useCallback(
    (values) => {
      const { richText, plainText } = getPlainAndRichTextAsString(values.additionalDetails);

      if (richText) {
        batch(() => {
          dispatch(
            workspaceActions.rfqUpdatePartial(pk, {
              additional_details_rich_text: richText,
              additional_details: plainText,
            })
          );
          dispatch(workspaceNextActions.setEditRfqDescription(false));
        });
      }
    },
    [dispatch, pk]
  );

  return (
    <Box>
      {!additionalDetails && !isEditRfqDescription ? "-" : null}
      <Formik
        render={(props) => (
          <RfqDescriptionFormBase {...props} defaultValue={additionalDetailsRichText as string} />
        )}
        initialValues={initialValues}
        onSubmit={handleRfqUpdatePartial}
      />
    </Box>
  );
};

export default RfqDescription;
