import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControl,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { modalsActions } from "@next/redux/modalsSlices";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormAddButton } from "../../form-add-button/form-add-button";
import { FormParentBox } from "../../form-parent-box";
import { ScopeFormTable } from "../../table/scope-form-table/scope-form-table";
import { AddSpecListModal } from "../../modals/add-spec-list-modal/add-spec-list-modal";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormFieldLabel, InfoTitle } from "../../form-field-label/form-field-label";
import { SelectSpecType } from "../../select-spec-type/select-spec-type";
import { SearchTextField } from "../../search-text-field/search-text-field";
import { TableLinesBox } from "../../table-lines-box/table-lines-box";
import { SideDrawer } from "../../side-drawer/side-drawer";
import { FormBoxWrapper } from "../../form-box/form-box-wrapper";
import {
  selectCurrentMessageThread,
  selectFormApprovedAt,
  selectFormApprovedBy,
  selectFormData,
  selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepApproved,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectIsInternalNotesDrawerOpen,
  selectIsLastStep,
  selectIsSideDrawerOpen,
  selectIsThereAnyStepInReviewProcess,
  selectIsThereUnsavedChangesInFormStep,
  selectMessageThreadsOfStep,
  selectQuestionnaireName,
  selectSpecsOfScope,
  selectTriggerForFetchingSpecsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import {
  A2psProcessControlFormData,
  FieldMandatoryText,
  PaginationSizeForFormTable,
  PartialStep,
  PomDistributorFormKeyMapping,
  PomManufacturerFormKeyMapping,
  PosEndUsersFormKeyMapping,
  Questionnaire,
  QuestionnaireForms,
  ScopeFormData,
  SpecificationTableRow,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showIncompleteStepsMessageForScopeForm } from "@next/modules/vendor-management/utils/showIncompleteStepsMessageForScopeForm";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import { showThreadsNeedToBeResolvedMessage } from "@next/modules/vendor-management/utils/showThreadsNeedToBeResolvedMessage";
import * as S from "./scope-form.styled";
import { ScopeFormDeletedSpecsBtn } from "./scope-form-deleted-specs-btn";
import { ScopeFormAddedSpecsBtn } from "./scope-form-added-specs-btn";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort-v5";
import { getScopeSortQueryString } from "../../table/scope-form-table/scope-form-table.helpers";

const tableBottomText =
  "Thanks to declare if any qualified specifications are missing from the list above, " +
  "click 'Add Spec' and search through the complete list to include them.(CP / Not controlled process / NDT, Test ...).";
const tableTopTextForPosEndUserAndPomManufacturer =
  "As per AIRBUS DATABASES, you are qualified as per following AIRBUS CANADA Technical specifications. Could you please confirm if this perimeter is still accurate ? If Lines not needed anymore thanks to delete the line.  In case of existing limitation or limitation to be declared : please add it into the additional comment section precising the A2PS description.";
const tableTopTextForPomDistributor =
  "Declare couples ordered by the distributor  : [Spec + Manufacturer Name + Manufacturer Site]";

const EMPTY_SCOPE_STEPS_NAMES_TO_SKIP_BY_FORM_TYPE: string[] = [
  PosEndUsersFormKeyMapping.A2psProcessControl,
  PosEndUsersFormKeyMapping.LeadTimeForImplementation,
  PosEndUsersFormKeyMapping.LeadTimeForProduction,
  //
  PomManufacturerFormKeyMapping.LeadTimeForImplementation,
  PomManufacturerFormKeyMapping.LeadTimeForProduction,
  //
  PomDistributorFormKeyMapping.BufferStockStrategy,
  PomDistributorFormKeyMapping.LeadTimeForImplementation,
];

export const ScopeForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isTableInPomDistributorQuestionnaire = questionnaireName === Questionnaire.PomDistributor;
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const scopeFormData = useSelector(selectFormData) as ScopeFormData & A2psProcessControlFormData;
  const [page, setPage] = useState(1);
  const specsOfScopeData = useSelector(selectSpecsOfScope);
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const isFormApproved = useSelector(selectIsFormStepApproved);
  const formApprovedAt = useSelector(selectFormApprovedAt);
  const formApprovedBy = useSelector(selectFormApprovedBy);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isFormFrozen = isFormApproved || isFormDisplayForValidationManagement;
  const isLastStep = useSelector(selectIsLastStep);
  const messageThreadsOfStep = useSelector(selectMessageThreadsOfStep);
  const isAllStepsCompletedExceptBeforeCurrentStepCompletion = useSelector(
    selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion
  );
  const isThereAnyStepInReview = useSelector(selectIsThereAnyStepInReviewProcess);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [specType, setSpecType] = useState<string | string[]>("");
  const [searchValue, setSearchValue] = useState("");
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const [hideApprovedTableLines, setHideApprovedTableLines] = useState(false);
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const isInternalNotesDrawerOpen = useSelector(selectIsInternalNotesDrawerOpen);

  const triggerStateForFetchingSpecs = useSelector(selectTriggerForFetchingSpecsOfStep);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const { control, watch, setValue, getValues } = useForm<ScopeFormData>({
    defaultValues: scopeFormData,
  });

  const { handleSortChange, sortQueryString, sortModel } =
    useTableFilterAndSort(getScopeSortQueryString);

  const skippedSteps = useMemo(() => {
    return watch("scope_is_empty")
      ? steps.filter((step) =>
          EMPTY_SCOPE_STEPS_NAMES_TO_SKIP_BY_FORM_TYPE.includes(step.step_name)
        )
      : [];
  }, [steps]);
  const skippedStepsIds = skippedSteps.map((step) => step.id);
  const skippedStepsStepIds = skippedSteps.map((step) => step.step);

  const nextStepIndex = useMemo(() => {
    if (watch("scope_is_empty")) {
      const lastStepName = skippedSteps[skippedSteps.length - 1].step_name;
      const newActiveStepIndex = steps.findIndex((step) => step.step_name === lastStepName) + 1;
      if (newActiveStepIndex === steps.length) {
        return newActiveStepIndex - 1;
      }

      return newActiveStepIndex;
    } else {
      return activeFormStepIndex + 1;
    }
  }, [skippedSteps, steps]);

  const isLastStepWithSkip = useMemo(() => {
    return nextStepIndex === steps.length - 1;
  }, [nextStepIndex, steps.length]);

  useEffect(() => {
    if (scopeFormData) {
      fetchSpecsOfScope();

      dispatch(
        vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest({
          companyId: companyId,
          stepId: step.step,
        })
      );
    }
  }, [
    page,
    searchValue,
    specType,
    hideCompletedTableLines,
    hideApprovedTableLines,
    sortQueryString,
    triggerStateForFetchingSpecs,
    isFetchSpecByClickedThread,
  ]);

  useEffect(() => {
    batch(() => {
      dispatch(vendorManagementActions.fetchRemovedSpecsOfScopeRequest({ companyId }));
      dispatch(vendorManagementActions.fetchAddedSpecsOfScopeRequest({ companyId }));
    });
  }, [companyId]);

  const fetchSpecsOfScope = () => {
    if (isFetchSpecByClickedThread) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId,
          search: null,
          specType: null,
          pagination: { page: 1, pageSize: PaginationSizeForFormTable },
          isInPomDistributor: isTableInPomDistributorQuestionnaire,
          stepId: step.step,
          filter_completed: false,
          filter_approved: false,
          ordering: sortQueryString,
          entityId: currentMessageThread?.entity_id,
        })
      );
    } else {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId,
          search: searchValue,
          specType: specType,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          isInPomDistributor: isTableInPomDistributorQuestionnaire,
          stepId: step.step,
          filter_approved: hideApprovedTableLines,
          filter_completed: hideCompletedTableLines,
          ordering: sortQueryString,
        })
      );
    }
  };

  const handleSpecTypeChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value;

    setSpecType(value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  const handleAddSpecToScope = (rows: SpecificationTableRow[]) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        rows: rows,
        onSuccess: onAddSpecSuccess,
      })
    );
  };

  const onAddSpecSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    setStatusOfStepAgainstChange();
    makeAlreadyCompletedSubsequentStepsIncomplete();
    makeAlreadyApprovedSubsequentStepsUnapproved();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId,
        pagination: { page: page, pageSize: PaginationSizeForFormTable },
        isInPomDistributor: isTableInPomDistributorQuestionnaire,
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        isForFetchingToGetCountData: !isTableInPomDistributorQuestionnaire,
      })
    );
  };

  const makeAlreadyCompletedSubsequentStepsIncomplete = () => {
    //all Steps names
    const a2psStepName = PosEndUsersFormKeyMapping.A2psProcessControl;
    const bufferStockName = PomDistributorFormKeyMapping.BufferStockStrategy;
    const leadtimeProdStepName = PosEndUsersFormKeyMapping.LeadTimeForProduction;
    const leadTimeImplStepName = PosEndUsersFormKeyMapping.LeadTimeForImplementation;

    //all Steps
    const bufferStockStratStep = steps.find((step) => step.step_name === bufferStockName);
    const leadTimeProdStep = steps.find((step) => step.step_name === leadtimeProdStepName);
    const a2psStep = steps.find((step) => step.step_name === a2psStepName);
    const leadTimeImplStep = steps.find((step) => step.step_name === leadTimeImplStepName);
    if (
      a2psStep?.is_completed &&
      scopeFormData?.has_captive_laboratory &&
      leadTimeImplStep?.is_completed
    ) {
      makeStepIncomplete(steps.indexOf(a2psStep));
      showIncompleteStepsMessageForScopeForm([a2psStepName, leadTimeImplStepName]);
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName]);
    } else if (a2psStep?.is_completed && scopeFormData?.has_captive_laboratory) {
      makeStepIncomplete(steps.indexOf(a2psStep));
      showIncompleteStepsMessageForScopeForm([a2psStepName]);
    } else if (leadTimeImplStep?.is_completed && leadTimeProdStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      makeStepIncomplete(steps.indexOf(leadTimeProdStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName, leadtimeProdStepName]);
    } else if (leadTimeImplStep?.is_completed && bufferStockStratStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      makeStepIncomplete(steps.indexOf(bufferStockStratStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName, bufferStockName]);
    } else if (leadTimeImplStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeImplStep));
      showIncompleteStepsMessageForScopeForm([leadTimeImplStepName]);
    } else if (leadTimeProdStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(leadTimeProdStep));
      showIncompleteStepsMessageForScopeForm([leadtimeProdStepName]);
    } else if (bufferStockStratStep?.is_completed) {
      makeStepIncomplete(steps.indexOf(bufferStockStratStep));
      showIncompleteStepsMessageForScopeForm([bufferStockName]);
    }
  };

  const makeStepIncomplete = (stepIndex: number) => {
    if (stepIndex !== -1) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, stepIndex);
    }
  };

  const makeAlreadyApprovedSubsequentStepsUnapproved = () => {
    const a2psStepName = PosEndUsersFormKeyMapping.A2psProcessControl;
    const bufferStockName = PomDistributorFormKeyMapping.BufferStockStrategy;
    const leadtimeProdStepName = PosEndUsersFormKeyMapping.LeadTimeForProduction;
    const leadTimeImplStepName = PosEndUsersFormKeyMapping.LeadTimeForImplementation;

    const bufferStockStratStep = steps.find((step) => step.step_name === bufferStockName);
    const leadTimeProdStep = steps.find((step) => step.step_name === leadtimeProdStepName);
    const a2psStep = steps.find((step) => step.step_name === a2psStepName);
    const leadTimeImplStep = steps.find((step) => step.step_name === leadTimeImplStepName);

    if (
      a2psStep?.approval_status === "approved" &&
      scopeFormData?.has_captive_laboratory &&
      leadTimeImplStep?.approval_status === "approved"
    ) {
      makeStepUnapproved(steps.indexOf(a2psStep));
      makeStepUnapproved(steps.indexOf(leadTimeImplStep));
    } else if (a2psStep?.approval_status === "approved" && scopeFormData?.has_captive_laboratory) {
      makeStepUnapproved(steps.indexOf(a2psStep));
    } else if (
      leadTimeImplStep?.approval_status === "approved" &&
      leadTimeProdStep?.approval_status === "approved"
    ) {
      makeStepUnapproved(steps.indexOf(leadTimeImplStep));
      makeStepUnapproved(steps.indexOf(leadTimeProdStep));
    } else if (
      leadTimeImplStep?.approval_status === "approved" &&
      bufferStockStratStep?.approval_status === "approved"
    ) {
      makeStepUnapproved(steps.indexOf(leadTimeImplStep));
      makeStepUnapproved(steps.indexOf(bufferStockStratStep));
    } else if (leadTimeImplStep?.approval_status === "approved") {
      makeStepUnapproved(steps.indexOf(leadTimeImplStep));
    } else if (leadTimeProdStep?.approval_status === "approved") {
      makeStepUnapproved(steps.indexOf(leadTimeProdStep));
    } else if (bufferStockStratStep?.approval_status === "approved") {
      makeStepUnapproved(steps.indexOf(bufferStockStratStep));
    }
  };

  const makeStepUnapproved = (stepIndex: number) => {
    if (stepIndex !== -1) {
      const step = steps[stepIndex];
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: "changes_requested",
        })
      );
    }
  };

  const saveChanges = (isForCompletion: boolean) => {
    const additionalComment = watch("additional_comment");
    dispatch(
      vendorManagementActions.saveAdditionalCommentFieldOfFormRequest({
        companyId: companyId,
        additionalComment,
        stepName: QuestionnaireForms.Scope,
      })
    );
    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion = () => {
    if (step?.unresolved_threads_count !== 0) {
      showThreadsNeedToBeResolvedMessage();
      return;
    }

    const tableRows = specsOfScopeData?.results?.data || [];
    if (watch("scope_is_empty")) {
      if (!watch("additional_comment")?.trim()) {
        setErrorAndShowInvalidMessage();
        return;
      }
      makeFormStepCompletionProcess();
      return;
    }

    if (questionnaireName === Questionnaire.PomDistributor) {
      const isTableRowsValidated = specsOfScopeData.results.uncompleted_count === 0;
      if (isTableRowsValidated && tableRows.length > 0) {
        makeFormStepCompletionProcess();
      } else {
        setErrorAndShowInvalidMessage();
      }
    } else if (tableRows.length > 0) {
      makeFormStepCompletionProcess();
    } else {
      setErrorAndShowInvalidMessage();
    }
  };

  const setErrorAndShowInvalidMessage = () => {
    showInvalidMessage();
    if (!showFormErrors) {
      setShowFormErrors(true);
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompletedExceptBeforeCurrentStepCompletion) {
      showThankYouModal();
    } else if (!isLastStep && !isThereAnyStepInReview) {
      if (isLastStepWithSkip) {
        showThankYouModal();
      }
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    setActiveFormStepIndex(nextStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const setStatusOfStepAgainstChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true, activeFormStepIndex);
    }
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];

    const partialStep: PartialStep = {
      [key]: value,
      next_steps_to_be_completed: key === "is_completed" && value ? skippedStepsIds : [],
    };

    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          step?.step_name === PosEndUsersFormKeyMapping.Scope
            ? key === "is_completed" && value
              ? () => updateApprovalOfStepAfterCompletionChange("changes_done")
              : key === "is_completed" && !value
                ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
                : undefined
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (
    statusParam: "changes_done" | "changes_requested"
  ) => {
    if (isFormInReview && step?.unresolved_threads_count === 0 && statusParam === "changes_done") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    } else if (step?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    }
  };

  const handleSpecsListChanges = useCallback(() => {
    setValue(
      "scope_is_empty",
      specsOfScopeData?.results?.data?.length === 0 && specsOfScopeData?.count === 0
    );
  }, [specsOfScopeData?.results, setValue]);

  useEffect(handleSpecsListChanges, [handleSpecsListChanges]);

  return (
    <>
      <FormParentBox>
        <FormBoxWrapper isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}>
          <FormBoxLg
            isCompleted={isFormCompleted}
            isApproved={isFormApproved}
            isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
          >
            <FormTitle
              title={"Scope"}
              isCompleted={isFormCompleted}
              isApproved={isFormApproved}
              isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
            />
            <Box>
              <S.StyledBox>
                <S.StyledTableBox>
                  <FormFieldLabel
                    label={
                      questionnaireName === Questionnaire.PomDistributor
                        ? tableTopTextForPomDistributor
                        : tableTopTextForPosEndUserAndPomManufacturer
                    }
                  />

                  {!isFormDisplayForValidationManagement &&
                  questionnaireName === Questionnaire.PomDistributor ? (
                    <InfoTitle />
                  ) : null}

                  {!isFormDisplayForValidationManagement ? (
                    <S.StyledRowBox>
                      <SelectSpecType specType={specType} handleChange={handleSpecTypeChange} />

                      <SearchTextField
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        clearSearch={clearSearch}
                      />
                    </S.StyledRowBox>
                  ) : null}

                  {questionnaireName === Questionnaire.PomDistributor ||
                  isFormDisplayForValidationManagement ? (
                    <TableLinesBox
                      completedCount={specsOfScopeData?.results?.completed_count}
                      approvedCount={specsOfScopeData?.results?.approved_count}
                      totalCount={specsOfScopeData?.results?.total_count}
                      hideCompletedTableLines={hideCompletedTableLines}
                      hideApprovedTableLines={hideApprovedTableLines}
                      setHideApprovedTableLines={setHideApprovedTableLines}
                      setHideCompletedTableLines={setHideCompletedTableLines}
                      setPage={setPage}
                      isFormDisplayForValidationManagement={isFormDisplayForValidationManagement}
                    />
                  ) : null}

                  <S.StyledTableWrapper>
                    <ScopeFormTable
                      hideCompletedTableLines={hideCompletedTableLines}
                      page={page}
                      setPage={setPage}
                      pageSize={PaginationSizeForFormTable}
                      isTableDisabled={isFormFrozen}
                      sortModel={sortModel}
                      onSortModelChange={handleSortChange}
                    />
                  </S.StyledTableWrapper>
                </S.StyledTableBox>

                {!isFormDisplayForValidationManagement ? (
                  <S.StyledBox mt={"24px"}>
                    <S.StyledInnerBox>
                      <Box>
                        <FormAddButton
                          btnText={"Add specs"}
                          onClick={onClickOpenAddSpecListModal}
                          disabled={isFormApproved || isFetchSpecByClickedThread}
                        />
                      </Box>

                      <S.StyledTypography variant="body2">{tableBottomText}</S.StyledTypography>
                    </S.StyledInnerBox>

                    {showFormErrors &&
                    specsOfScopeData?.results?.data.length === 0 &&
                    !watch("scope_is_empty") ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                  </S.StyledBox>
                ) : null}
              </S.StyledBox>
              {specsOfScopeData?.results?.data.length === 0 ? (
                <>
                  <Typography variant="subtitle1" color="gray" mt={"20px"} mb={"4px"}>
                    Or
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="scope_is_empty"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value || false}
                          value={value || false}
                          color="primary"
                          onChange={onChange}
                          size="medium"
                          sx={{ ml: "-10px" }}
                        />
                      )}
                    />

                    <Typography variant="body2">
                      {
                        "I confirm that my scope is empty. If so, please provide a comment below to validate this step."
                      }
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={"12px"}>
              <ScopeFormAddedSpecsBtn />
              <ScopeFormDeletedSpecsBtn />
            </Box>

            <FormControl margin="none">
              <Controller
                name="additional_comment"
                control={control}
                defaultValue={scopeFormData?.additional_comment || ""}
                render={({ field: { onChange, value } }) => (
                  <S.StyledFormFieldBox>
                    <TextField
                      sx={{ width: "100%" }}
                      multiline
                      rows={3}
                      placeholder="Additional comment..."
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                  </S.StyledFormFieldBox>
                )}
              />
              <Box height={"24px"} />
              {showFormErrors && watch("scope_is_empty") && !watch("additional_comment")?.trim() ? (
                <FormFieldErrorTypography text={FieldMandatoryText} />
              ) : null}
            </FormControl>
          </FormBoxLg>
        </FormBoxWrapper>

        <SideDrawer isThreadful={true} />
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={confirmStepCompletion}
        isCompleted={isFormCompleted}
        isApproved={isFormApproved}
        isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
        isShowReviewButton={watch("scope_is_empty")}
        nextStepsToBeApproved={skippedStepsStepIds}
        approvedAt={formApprovedAt}
        approvedBy={formApprovedBy}
      />

      <AddSpecListModal
        title={"Spec list"}
        rowsAlreadyAdded={specsOfScopeData?.results?.data || []}
        handleAddSpecToFormTable={handleAddSpecToScope}
      />
    </>
  );
};
