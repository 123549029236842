import React from "react";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import * as S from "./table-lines-box.styled";

type Props = {
  // Props for supplier view
  completedCount?: number;
  hideCompletedTableLines?: boolean;
  setHideCompletedTableLines?: React.Dispatch<React.SetStateAction<boolean>>;
  // Props for SCQM buyer view
  approvedCount?: number;
  hideApprovedTableLines?: boolean;
  setHideApprovedTableLines?: React.Dispatch<React.SetStateAction<boolean>>;
  // Props for both views
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  isFormDisplayForValidationManagement?: boolean;
  totalCount: number;
};

export const TableLinesBox: React.FC<Props> = ({
  completedCount,
  approvedCount,
  totalCount,
  hideCompletedTableLines,
  setHideCompletedTableLines,
  hideApprovedTableLines,
  setHideApprovedTableLines,
  setPage,
  isFormDisplayForValidationManagement,
}) => {
  const handleSwitchChange = () => {
    if (setPage) {
      setPage(1);
    }
    if (isFormDisplayForValidationManagement) {
      setHideApprovedTableLines((prev) => !prev);
    } else {
      setHideCompletedTableLines((prev) => !prev);
    }
  };

  return (
    <S.StyledBox>
      <S.StyledTypography variant="body1" tooltipTitle={null}>
        {isFormDisplayForValidationManagement
          ? `Lines approved: ${approvedCount}/${totalCount}`
          : `Lines completed: ${completedCount}/${totalCount}`}
      </S.StyledTypography>

      <FormControl>
        {isFormDisplayForValidationManagement ? (
          <FormControlLabel
            checked={hideApprovedTableLines}
            onChange={handleSwitchChange}
            control={<Switch color="primary" />}
            label="Hide approved lines"
            labelPlacement="end"
          />
        ) : (
          <FormControlLabel
            checked={hideCompletedTableLines}
            onChange={handleSwitchChange}
            control={<Switch color="primary" />}
            label="Hide completed lines"
            labelPlacement="end"
          />
        )}
      </FormControl>
    </S.StyledBox>
  );
};
