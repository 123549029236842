import { Chip, alpha } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { LineItemActionType, LineItemType, OrderStateObj } from "../redux";
import {
  getOrderStateTagIcon,
  getOrderStateTagStyle,
} from "./order-detail/order-detail-header/order-detail-header.utils";
import { CustomTypography } from "@next/components/custom-typography";
import { AppTheme } from "layout/AppTheme";
import { useTranslation } from "react-i18next";

type Props = {
  state?: OrderStateObj;
  className?: string;
  tooltipTitle?: string;
};

export const OrderStateTag: React.FC<Props> = ({ state, className, tooltipTitle }) => {
  return (
    <Chip
      label={
        <CustomTypography tooltipTitle={tooltipTitle ? tooltipTitle : ""}>
          {state?.description
            ? `${state?.display_name}: ${state?.description}`
            : state?.display_name}
        </CustomTypography>
      }
      icon={getOrderStateTagIcon(state?.slug)}
      style={getOrderStateTagStyle(state?.slug)}
      className={className}
    />
  );
};

type LineItemUpdateTypeTagProps = {
  type: LineItemType;
  className?: string;
};

export const LineItemUpdateTypeTag: React.FC<LineItemUpdateTypeTagProps> = ({
  type,
  className,
}) => {
  const { t } = useTranslation();
  const [tagName, setTagName] = useState<string>("");
  useEffect(() => {
    switch (type) {
      case LineItemType.PRICE:
        setTagName(t("orders:orderDetail:lineItemTypeTag:price"));
        break;
      case LineItemType.QUANTITY:
        setTagName(t("orders:orderDetail:lineItemTypeTag:qty"));
        break;
      case LineItemType.UPDATED_DELIVERY_DATE:
        setTagName(t("orders:orderDetail:lineItemTypeTag:shipping"));
        break;
    }
  }, [type]);
  return (
    <Chip
      label={<CustomTypography>{tagName}</CustomTypography>}
      size="small"
      style={{
        cursor: "unset",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: AppTheme.palette.warning.dark,
        backgroundColor: alpha(AppTheme.palette.warning.main, 0.1),
      }}
      className={className}
    />
  );
};

type LineItemActionTypeTagProps = {
  type: LineItemActionType;
  className?: string;
};

export const LineItemActionTypeTag: React.FC<LineItemActionTypeTagProps> = ({
  type,
  className,
}) => {
  const { t } = useTranslation();
  const tagName = useMemo(() => {
    return type === LineItemActionType.ADDED
      ? t("orders:orderDetail:lineItemActionTag:added")
      : type === LineItemActionType.DELETED
        ? t("orders:orderDetail:lineItemActionTag:deleted")
        : "";
  }, [type]);

  if (!tagName) return null;

  return (
    <Chip
      label={<CustomTypography>{tagName}</CustomTypography>}
      size="small"
      style={{
        cursor: "unset",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color:
          type === LineItemActionType.ADDED
            ? AppTheme.palette.primary.dark
            : AppTheme.palette.warning.dark,
        backgroundColor:
          type === LineItemActionType.ADDED
            ? alpha(AppTheme.palette.primary.main, 0.1)
            : alpha(AppTheme.palette.warning.main, 0.1),
      }}
      className={className}
    />
  );
};
