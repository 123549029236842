import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import { ValidationCell } from "../table-cells/validation-cell";
import { SpecCell } from "../table-cells/spec-cell";
import { RemoveBtnCell } from "../table-cells/remove-btn-cell";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
};

export const getAirbusSpecificationTableColumns = (
  isFormDisplayForValidationManagement: boolean,
  isTableDisabled: boolean
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      sortable: true,
      field: "name",
      headerName: "Airbus specification",
      renderCell: (params) =>
        !isFormDisplayForValidationManagement ? (
          <SpecCell {...params} text={params?.row?.name} />
        ) : undefined,
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash Ref",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "scope_limitation",
      headerName: "Scope/Limitation",
      editable: !isTableDisabled,
      renderHeader: (params) => <HeaderCell {...params} required={true} />,
      renderCell: (params) => (
        <EditableTemplateCell {...params} placeholderText="Write in plain text..." />
      ),
      headerAlign: "left" as const,
    },
  ];

  const lastColumn = !isFormDisplayForValidationManagement
    ? {
        ...defaultColumnConfig,
        field: "removeRowButton",
        headerName: " ",
        align: "center" as const,
        renderCell: (params: GridRenderCellParams) => {
          return <RemoveBtnCell disabled={isTableDisabled} {...params} />;
        },
        flex: 0.1,
      }
    : {
        ...defaultColumnConfig,
        field: "action",
        headerName: "Action",
        renderCell: (params: GridRenderCellParams) => {
          return <ValidationCell params={params} />;
        },
        minWidth: 180,
      };

  columns.push(lastColumn);

  return columns;
};
