/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "assets/configi18n/i18n";
import { getOrdersTableColumns } from "./orders-table.columns";
import { getLoadingRows } from "helpers/data-view.helper-v5";
import { selectOrders, selectOrdersLoading } from "../../redux/selectors";
import { OrderState, ordersActions } from "../../redux";
import { generatePaginationAndSearchQuery } from "@next/utils/paginationUtils";
import { SHORT_PAGINATION_PAGE_SIZE } from "../../../../constants";
import { CardOrderState } from "../orders-table/select-order-state-cards/select-order-state.cards.types";
import { useTableSavedQueryPagination } from "@next/hooks/useTableSavedQueryPagination";
import useSavedGridState from "@next/hooks/useSavedGridState-v5";
import { ORDERS_TABLE_NAVIGATION_KEY } from "./orders-table";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import {
  ORDERS_GRID_COLUMN_STATE,
  ORDERS_GRID_TABLE_NAME,
} from "@next/constants/data-grid-state-constants";
import { FavoriteSupplier, NoteType } from "@next/modules/workspace/redux";

type Props = {
  isCompletedOrders: boolean;
  currentPage: number;
  selectedStateFilter?: CardOrderState;
  selectedSuppliers?: FavoriteSupplier[];
  selectedNote?: NoteType[];
  sortQueryString: string;
  filterQueryString: string;
  moduleType?: string;
};

export const useOrdersTableData = ({
  isCompletedOrders,
  currentPage,
  selectedStateFilter,
  selectedSuppliers,
  selectedNote,
  sortQueryString,
  filterQueryString,
  moduleType,
}: Props) => {
  const { savedTableQueryPagination } = useTableSavedQueryPagination(ORDERS_TABLE_NAVIGATION_KEY);
  const { restoreState } = useSavedGridState(ORDERS_GRID_COLUMN_STATE, ORDERS_GRID_TABLE_NAME);
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const ordersLoading = useSelector(selectOrdersLoading);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const ordersColumns = useMemo(
    () =>
      restoreState(
        getOrdersTableColumns(savedTableQueryPagination?.lsr, !hasViewerRole, moduleType)
      ),
    [i18n?.language, savedTableQueryPagination?.lsr, ordersLoading]
  );

  const [gridData, setGridData] = useState<any>({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    if (ordersLoading) {
      const loadingGridData = getLoadingRows(ordersColumns) as any;
      setGridData(loadingGridData);
    } else {
      setGridData({ rows: orders, columns: ordersColumns });
    }
  }, [orders, ordersLoading, ordersColumns]);

  useEffect(() => {
    let query = `is_completed=${isCompletedOrders}&${generatePaginationAndSearchQuery({
      currentPage,
      pageSize: SHORT_PAGINATION_PAGE_SIZE,
      sortQueryString,
      filterQueryString,
    })}`;

    if (selectedStateFilter && selectedStateFilter.slug !== OrderState.ALL && !isCompletedOrders) {
      query += `state=${selectedStateFilter?.query_slugs?.join(",")}&`;
    }
    if (selectedSuppliers && selectedSuppliers?.length > 0) {
      query += `supplier=${selectedSuppliers.map((item) => item?.pk).join(",")}`;
    }

    if (selectedNote && selectedNote?.length > 0) {
      const selectedNotesPks = selectedNote.map((note) => note.pk);
      if (selectedSuppliers?.length > 0) {
        query += `&notes=${selectedNotesPks.join(",")}`;
      } else {
        query += `notes=${selectedNotesPks.join(",")}`;
      }
    }

    dispatch(
      ordersActions.fetchOrdersRequest({
        query,
      })
    );
  }, [
    isCompletedOrders,
    currentPage,
    selectedStateFilter?.slug,
    selectedSuppliers?.length,
    selectedNote,
    sortQueryString,
    filterQueryString,
  ]);

  return {
    gridData,
  };
};
