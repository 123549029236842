import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PartialStep, Step, vendorManagementActions } from "../../redux";
import {
  selectIsAllStepsApprovalNull,
  selectIsAllStepsCompleted,
  selectIsFormDisplayForValidationManagement,
  selectIsLastStep,
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "../../redux/selectors";
import { formatTime } from "../../utils/formatTime";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import { VendorContactSupportItem } from "@next/modules/app/components/app-bar-next/app-bar-next-action-items/components/vendor-contact-support-action-item";
import { getUser } from "services/profile/profile.selectors";
import { showNeedToApproveEachSpecMessage } from "../../utils/showNeedToApproveEachSpecMessage";
import * as S from "./form-footer.styled";
import { formatDateAndTime } from "helpers/formatDate";
import { WorkspaceModalTypes } from "@next/modules/workspace/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";

interface FormFooterProps {
  saveChanges: () => void; // api call to just save
  confirmStepCompletion: () => void; // call to save and validate
  isCompleted: boolean;
  isInReview: boolean;
  isApproved: boolean;
  isShowReviewButton: boolean;
  nextStepsToBeApproved?: number[];
  approvedAt: string | null;
  approvedBy: string | null;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  saveChanges,
  confirmStepCompletion,
  isCompleted,
  isInReview,
  isApproved,
  isShowReviewButton,
  nextStepsToBeApproved = [],
  approvedAt,
  approvedBy,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const user = useSelector(getUser);
  const userType = user?.user_type;
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const isLastStep = useSelector(selectIsLastStep);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep: Step = steps[activeFormStepIndex];
  const isFirstStep = activeFormStepIndex === 0;
  const lastUpdatedDate = steps[activeFormStepIndex]?.last_updated;
  const lastSavedAt = formatTime(lastUpdatedDate);
  const isShowGreetingPage = activeFormStepIndex === -1;
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isAllStepsCompleted = useSelector(selectIsAllStepsCompleted);
  const isAllStepsApprovalNull = useSelector(selectIsAllStepsApprovalNull);
  const isReadOnly = !isAllStepsCompleted && isAllStepsApprovalNull;

  const handleNext = () => {
    if (isThereUnsavedChanges) {
      saveChanges();
    }
    routeNextStep();
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    makeFormStepActiveByIndex(newActiveStepIndex);
  };

  const handleBack = () => {
    if (isThereUnsavedChanges) {
      saveChanges();
    }
    routePreviousStep();
  };

  const routePreviousStep = () => {
    const newActiveStepIndex = activeFormStepIndex - 1;
    makeFormStepActiveByIndex(newActiveStepIndex);
  };

  const makeFormStepActiveByIndex = (index: number) => {
    const step = steps[index];
    const partialStep: PartialStep = {
      is_active: true,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
    dispatch(vendorManagementActions.makeFormDataUndefined()); // to reset form data
  };

  const handleClickMarkAsApprovedBtn = () => {
    if (!isShowReviewButton) {
      // if review button is not shown, step is threadful, so we don't allow to approve it directly
      showNeedToApproveEachSpecMessage();
    } else {
      updateApprovalStatusOfStep("approved");
    }
  };

  const handleOpenDocumentsDownloadPage = () => {
    dispatch(modalsActions.closeModal(WorkspaceModalTypes.CONTACT_US_MODAL));
    if (!isShowGreetingPage && !isFormDisplayForValidationManagement) {
      makeActiveStepInactive(activeFormStepIndex);
    }
  };

  const makeActiveStepInactive = (index: number, allVisibleSteps = steps) => {
    const step = allVisibleSteps[index];
    const partialStep: PartialStep = {
      is_active: false,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const updateApprovalStatusOfStep = (status: "approved") => {
    const currentStep = steps[activeFormStepIndex];
    dispatch(
      vendorManagementActions.updateApprovalStatusOfStepRequest({
        companyId: companyId,
        stepId: currentStep.step,
        status: status,
        steps_to_be_approved: nextStepsToBeApproved,
      })
    );
  };

  const openDrawer = () => {
    dispatch(vendorManagementActions.setIsDrawerOpen(true));
  };

  const publicFooterBtns = (
    <>
      <S.StyledButton
        startIcon={<S.StyledSaveIcon />}
        variant="contained"
        color="primary"
        onClick={saveChanges}
        disabled={isCompleted}
      >
        <S.StyledTypography variant="body2" tooltipTitle={null}>
          Save changes
        </S.StyledTypography>
      </S.StyledButton>

      <S.SavedBox>
        <S.StyledSavedTypography variant="body2">
          Last saved at {lastSavedAt}
        </S.StyledSavedTypography>
      </S.SavedBox>

      <S.StyledButton
        startIcon={<S.StyledCheckIcon />}
        variant="contained"
        color="success"
        onClick={confirmStepCompletion}
        disableElevation
        disabled={isCompleted}
      >
        <S.StyledTypography variant="body2" tooltipTitle={null}>
          Confirm step completion
        </S.StyledTypography>
      </S.StyledButton>
      <VendorContactSupportItem openDocumentsDownloadPage={handleOpenDocumentsDownloadPage} />
    </>
  );

  const sessionedFooterBtns = (
    <>
      {isShowReviewButton ? (
        <S.StyledReviewButton
          variant="outlined"
          color="secondary"
          size="medium"
          disabled={!isCompleted || isInReview}
          onClick={openDrawer}
        >
          <S.StyledTypography variant="body2" tooltipTitle={null}>
            Start a review
          </S.StyledTypography>
        </S.StyledReviewButton>
      ) : null}

      {approvedAt && approvedBy ? (
        <>
          <S.StyledTypography variant="body2" sx={{ textWrap: "nowrap" }}>
            Approved on {formatDateAndTime(approvedAt, "MMMM Do YYYY,", "h:mm a")} by{" "}
            <b>{approvedBy}</b>
          </S.StyledTypography>
        </>
      ) : null}

      <S.StyledButton
        startIcon={<S.StyledCheckIcon />}
        variant="contained"
        color="success"
        onClick={handleClickMarkAsApprovedBtn}
        disableElevation
        disabled={
          !isCompleted || isApproved || (isInReview && activeStep?.unresolved_threads_count !== 0)
        }
        sx={{ width: "auto" }}
      >
        <S.StyledTypography variant="body2" tooltipTitle={null}>
          Mark as approved
        </S.StyledTypography>
      </S.StyledButton>
    </>
  );

  return (
    <S.StyledFooterBox>
      <S.StyledInnerBox>
        {!isFirstStep ? (
          <S.StyledDirectionButton onClick={handleBack}>
            <ArrowBackIcon />
          </S.StyledDirectionButton>
        ) : (
          <S.BlankBox />
        )}

        {!isFormDisplayForValidationManagement
          ? publicFooterBtns
          : userType === "approver" && !isReadOnly
            ? sessionedFooterBtns
            : null}

        {!isLastStep ? (
          <S.StyledDirectionButton onClick={handleNext}>
            <ArrowForwardIcon />
          </S.StyledDirectionButton>
        ) : (
          <S.BlankBox />
        )}
      </S.StyledInnerBox>
    </S.StyledFooterBox>
  );
};
