import React from "react";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { EditableTemplateCell } from "../table-cells/editable-template-cell";
import { HeaderCell } from "../table-cells/header-cell";
import { ValidationCell } from "../table-cells/validation-cell";
import { SpecCell } from "../table-cells/spec-cell";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getLeadTimeForProductionFormTableColumns = (
  isFormDisplayForValidationManagement: boolean,
  isTableDisabled: boolean
) => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      sortable: true,
      field: "name",
      headerName: "Specification",
      renderCell: (params) =>
        !isFormDisplayForValidationManagement ? (
          <SpecCell {...params} text={params?.row?.name} />
        ) : undefined,
    },
    {
      ...defaultColumnConfig,
      field: "dash_ref",
      headerName: "Dash",
      renderCell: (params) => {
        const value = params.value;
        return value === "nan" ? "n/a" : value;
      },
    },
    {
      ...defaultColumnConfig,
      field: "revision",
      headerName: "Revision",
    },
    {
      ...defaultColumnConfig,
      field: "controlled_process",
      headerName: "Controlled process",
    },
    {
      ...defaultColumnConfig,
      field: "description",
      headerName: "Description",
    },
    {
      ...defaultColumnConfig,
      field: "initial_industrialisation_capacity",
      headerName: "Initial industrialisation capacity",
      type: "number",
      editable: !isTableDisabled,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "new_industrialisation_capacity",
      headerName: "New industrialisation capacity",
      type: "number",
      editable: !isTableDisabled,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "changes_detail",
      headerName: "Please detail the change that will occur into the flow",
      editable: !isTableDisabled,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Click to edit..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "moq",
      headerName: "Min Order Quantity (MOQ)",
      type: "number",
      editable: !isTableDisabled,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
    {
      ...defaultColumnConfig,
      field: "production_lead_time",
      headerName: "Lead Time for production (Ax)? (weeks)",
      type: "number",
      editable: !isTableDisabled,
      renderHeader: (params: GridColumnHeaderParams) => <HeaderCell {...params} required={true} />,
      renderCell: (params: GridRenderCellParams) => (
        <EditableTemplateCell {...params} placeholderText="Type a number..." />
      ),
    },
  ];

  const validationColumn = {
    ...defaultColumnConfig,
    field: "action",
    headerName: "Action",
    renderCell: (params: GridRenderCellParams) => {
      return <ValidationCell params={params} />;
    },
    minWidth: 180,
  };

  if (isFormDisplayForValidationManagement) {
    columns.push(validationColumn);
  }

  return columns;
};
