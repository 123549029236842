import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { StatusCell } from "../../table/table-cells/status-cell";
import { ValidationManagementTableRow } from "@next/modules/vendor-management/redux";
import { TextCell } from "@next/modules/analytics/components/report-table-cells/text-cell";
import { Box } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

export const getValidationManagementTableColumns = () => {
  const columns = [
    {
      ...defaultColumnConfig,
      field: "company",
      headerName: "Name",
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: "vendor_code",
      headerName: "Vendor code",
      renderCell: TextCell,
    },
    {
      ...defaultColumnConfig,
      field: "questionnaire_name",
      headerName: "Type",
    },
    {
      ...defaultColumnConfig,
      field: "scope",
      headerName: "Scope",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as ValidationManagementTableRow;
        let text = null;
        if (row.specs_count > 0) {
          text = `${row.specs_count} spec(s)`;
        }
        if (row.drawings_count > 0) {
          text += text ? `, ${row.drawings_count} drawing(s)` : `${row.drawings_count} drawings(s)`;
        }
        return text;
      },
    },
    {
      ...defaultColumnConfig,
      field: "status",
      headerName: "Status",
      renderCell: StatusCell,
    },
    {
      ...defaultColumnConfig,
      field: "step_completed",
      headerName: "Steps completed",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as ValidationManagementTableRow;

        const text = `${row.completed_steps_count}/${row.total_steps_count}`;
        return text;
      },
    },
    {
      ...defaultColumnConfig,
      field: "step_approved",
      headerName: "Steps approved",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as ValidationManagementTableRow;

        const text = `${row.approved_steps_count}/${row.total_steps_count}`;
        return text;
      },
    },
    {
      ...defaultColumnConfig,
      field: "approvers",
      headerName: "Approver(s)",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as ValidationManagementTableRow;
        const text = row.approvers.join(", ") || "-";
        return (
          <CustomTypography
            tooltipTitle={
              <Box style={{ textAlign: "center" }}>
                {row.approvers.map((approver) => (
                  <Box key={approver}>{approver}</Box>
                ))}
              </Box>
            }
          >
            {text}
          </CustomTypography>
        );
      },
    },
  ];

  return columns;
};
