import { RootState } from "store";

export const getMaterialsOptions = (state: RootState) => {
  const materials = state.partConfiguration.materials;

  const materialData: any[] = [];
  if (materials) {
    materials.map((material: any) => {
      material.alloys.forEach((alloy: any) => {
        materialData.push({
          label: alloy?.name,
          value: alloy?.id,
          type: material?.name,
        });
      });
    });
  }

  return materialData;
};

export const getProcessesOptions = (state: RootState) => {
  return state.partConfiguration.processes.map((process: any) => ({
    label: process.name,
    value: process.id,
    type: process.type,
  }));
};

export const getTreatmentOptions = (state: RootState) => {
  return state.partConfiguration.treatment.map((treatment: any) => ({
    label: treatment.name,
    value: treatment.pk,
    type: treatment.type,
  }));
};

export const getPartExtraFields = (state: RootState) => state.partConfiguration.partExtraFields;

export const getQuotationExtraFields = (state: RootState) =>
  state.partConfiguration.quotationExtraFields;

export const getRfqStatuses = (state: RootState) => state.partConfiguration.rfqStatuses;

export const getNetDs = (state: RootState) => state.partConfiguration.netDs;

export const getRfqIntentToNoQuoteReasons = (state: RootState) =>
  state.partConfiguration.rfqItnbReasons;

export const getIsLastRFQPublic = (state: RootState) => state.partConfiguration.isLastRFQPublic;

export const getPartDefaults = (state: RootState) => state.partConfiguration.partDefaults;

export const getBuyersNetwork = (state: RootState) => state.partConfiguration.buyersNetwork;

export const getPMPartsHiddenColumns = (state: RootState) =>
  state.partConfiguration.pmPartsHiddenColumns;

export const getAnnouncementsConfig = (state: RootState) => state.partConfiguration.announcements;

export const getReportsButtonAvailable = (state: RootState) =>
  state.partConfiguration.reportsButtonAvailable;

export const getTodayRFQsCount = (state: RootState) => state.partConfiguration.todayRFQsCount;

export const getErpConfiguration = (state: RootState) => state.partConfiguration.erpConfiguration;

export const selectPartConfiguration = (state: RootState) => state.partConfiguration;

export const selectPartConfigurationLoading = (state: RootState) =>
  state.partConfiguration.isLoading;

export const partConfigurationSelectors = {
  getMaterialsOptions,
  getProcessesOptions,
  getTreatmentOptions,
  getPartExtraFields,
  getRfqStatuses,
  getNetDs,
  getRfqIntentToNoQuoteReasons,
  getPartDefaults,
  getBuyersNetwork,
  getPMPartsHiddenColumns,
  getAnnouncementsConfig,
  selectPartConfiguration,
  selectPartConfigurationLoading,
};
