import { GridSortModel } from "@mui/x-data-grid-pro-v5";

export const getLeadTimeForProductionSortQueryString = (sortModel: GridSortModel) => {
  if (!sortModel.length) return "";
  let queryString = "";
  sortModel.forEach((item, index) => {
    const directionStr = item.sort === "desc" ? "-" : "";
    if (index > 0) {
      queryString += ",";
    }
    switch (item.field) {
      case "name":
        queryString += `${directionStr}name`;
        break;
    }
  });
  return `${queryString}`;
};
