import React from "react";
import { ReadinessDashboardOverviewSemiDonutChartsGroup } from "./readiness-dashboard-overview-semi-donut-charts-group/readiness-dashboard-overview-semi-donut-charts-group";
import { ReadinessDashboardOverviewGraphChartsGroup } from "./readiness-dashboard-overview-graph-charts-group/readiness-dashboard-overview-graph-charts-group";
import { ReadinessDashboardOverviewSuppliersTableCard } from "./readiness-dashboard-overview-suppliers-table-card/readiness-dashboard-overview-suppliers-table-card";
import { ReadinessDashboardOverviewScopeSuppliersTiles } from "./readiness-dashboard-overview-scope-suppliers-tiles/readiness-dashboard-overview-scope-suppliers-tiles";
import * as S from "./readiness-dashboard-overview.styled";

export const ReadinessDashboardOverview: React.FC = () => {
  return (
    <S.StyledRootBox>
      <ReadinessDashboardOverviewScopeSuppliersTiles />

      <ReadinessDashboardOverviewSemiDonutChartsGroup />

      <ReadinessDashboardOverviewGraphChartsGroup />

      <ReadinessDashboardOverviewSuppliersTableCard />
    </S.StyledRootBox>
  );
};
