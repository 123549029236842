import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";
import { FormFooter } from "../../form-footer/form-footer";
import { FormTitle } from "../../form-title/form-title";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormParentBox } from "../../form-parent-box";
import { SideDrawer } from "../../side-drawer/side-drawer";
import { FormBoxWrapper } from "../../form-box/form-box-wrapper";
import {
  FieldMandatoryText,
  OnboardingFormData,
  OnboardingRadioGroupKeysWithLabel,
  PartialStep,
  QuestionnaireForms,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectFormApprovedAt,
  selectFormApprovedBy,
  selectFormData,
  selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepApproved,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectIsInternalNotesDrawerOpen,
  selectIsLastStep,
  selectIsSideDrawerOpen,
  selectIsThereAnyStepInReviewProcess,
  selectIsThereUnsavedChangesInFormStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import { showThreadsNeedToBeResolvedMessage } from "@next/modules/vendor-management/utils/showThreadsNeedToBeResolvedMessage";
import * as S from "./onboarding-form.styled";

export const OnboardingForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const isFormApproved = useSelector(selectIsFormStepApproved);
  const formApprovedAt = useSelector(selectFormApprovedAt);
  const formApprovedBy = useSelector(selectFormApprovedBy);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeFormStep = steps[activeFormStepIndex];
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompletedExceptBeforeCurrentStepCompletion = useSelector(
    selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion
  );
  const isThereAnyStepInReview = useSelector(selectIsThereAnyStepInReviewProcess);
  const isFormFrozen = isFormApproved || isFormDisplayForValidationManagement;
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const isInternalNotesDrawerOpen = useSelector(selectIsInternalNotesDrawerOpen);

  const onboardingFormDataState = useSelector(selectFormData) as OnboardingFormData;
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<OnboardingFormData>({
    defaultValues: onboardingFormDataState,
  });

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.Onboarding,
        companyId: companyId,
      })
    );
    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<OnboardingFormData> = (data) => {
    if (activeFormStep?.unresolved_threads_count !== 0) {
      showThreadsNeedToBeResolvedMessage();
      return;
    }

    const isValidated = validateRadioGroupsAndSetError();
    if (isValidated) {
      makeFormStepCompletionProcess();
    } else {
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompletedExceptBeforeCurrentStepCompletion) {
      showThankYouModal();
    } else if (!isLastStep && !isThereAnyStepInReview) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const validateRadioGroupsAndSetError = () => {
    let isValid = true;
    const formValues = watch();
    const formData = formValues;
    const keys = Object.keys(OnboardingRadioGroupKeysWithLabel);
    keys.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        setError(key as keyof OnboardingFormData, { type: "manual" });
        isValid = false;
      }
    });
    return isValid;
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_done")
            : key === "is_completed" && !value
              ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
              : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (
    statusParam: "changes_done" | "changes_requested"
  ) => {
    if (
      isFormInReview &&
      activeFormStep?.unresolved_threads_count === 0 &&
      statusParam === "changes_done"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    } else if (
      activeFormStep?.approval_status === "changes_done" &&
      statusParam === "changes_requested"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  const renderRadioGroupField = (
    formFieldLabelComponent: React.ReactNode,
    key: keyof OnboardingFormData
  ) => {
    return (
      <FormControl fullWidth margin="none" error={errors[key] ? true : false}>
        <FormFieldBox>
          {formFieldLabelComponent}

          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <>
                <S.StyledRadioGroup
                  {...field}
                  value={field?.value === null ? "" : field.value}
                  onChange={(e) => {
                    const value = e.target.value === "true" ? true : false;
                    field.onChange(value);
                    checkInputChange();
                  }}
                >
                  <S.StyledFormControlLabel
                    value="true"
                    control={<S.StyledRadio />}
                    label="Yes"
                    disabled={isFormFrozen}
                  />
                  <S.StyledFormControlLabel
                    value="false"
                    control={<S.StyledRadio />}
                    label="No"
                    disabled={isFormFrozen}
                  />
                </S.StyledRadioGroup>
                {errors[key] ? <FormFieldErrorTypography text={FieldMandatoryText} /> : null}
              </>
            )}
          />
        </FormFieldBox>
      </FormControl>
    );
  };

  return (
    <>
      <FormParentBox>
        <FormBoxWrapper isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}>
          <FormBoxMd
            isCompleted={isFormCompleted}
            isApproved={isFormApproved}
            isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
            isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}
          >
            <FormTitle
              title={"Supplier access onboarding"}
              isCompleted={isFormCompleted}
              isApproved={isFormApproved}
              isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
            />

            <FormFieldLabel
              label={"Please declare here under the applications you have currently access to:"}
            />

            {Object.entries(OnboardingRadioGroupKeysWithLabel).map(([key, label]) => (
              <Fragment key={key}>
                {renderRadioGroupField(
                  <FormFieldLabel label={label} />,
                  key as keyof OnboardingFormData
                )}

                {key === "normaster" ? <FormTitle title={"IT Autonomy"} /> : null}
              </Fragment>
            ))}

            <FormControl margin="none">
              <Controller
                name="additional_comment"
                control={control}
                defaultValue={onboardingFormDataState?.additional_comment || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    multiline
                    rows={3}
                    placeholder="Additional comment..."
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      checkInputChange();
                    }}
                    InputProps={{
                      readOnly: isFormFrozen,
                    }}
                  />
                )}
              />
            </FormControl>
          </FormBoxMd>
        </FormBoxWrapper>

        <SideDrawer isThreadful={false} />
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
        isApproved={isFormApproved}
        isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
        isShowReviewButton={true}
        approvedAt={formApprovedAt}
        approvedBy={formApprovedBy}
      />
    </>
  );
};
