import {
  Badge,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIconProps,
  Theme,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { t } from "assets/configi18n/i18n";
import { history } from "helpers/history";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ExternalLinkActionItem } from "./app-bar-next-action-items/components/external-link-action-item";
import { AppBarItemKey, PINNED_PAGES_LOCAL_STORAGE_KEY } from "./app-bar-next.definitions";
import { getSelectedLanguage } from "@next/utils/browserUtils";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTheme } from "layout/AppTheme";
import { AppBarNextIcon } from "./app-bar-next-drawer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import useLocalStorage from "hooks/useLocalStorage";
import snackbarUtils from "@next/utils/snackbarUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipStyle: {
      height: "24px",
      fontSize: "12px",
      marginLeft: "5px",
      "& .MuiChip-label": {
        padding: "4px 6px",
      },
      cursor: "pointer",
    },
    selectRoot: {
      ".MuiPopover-paper": {
        marginTop: "23px",
        border: "1px solid red",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.background.default,
      },
      color: theme.palette.background.default,
      background: theme.palette.appbar.light,
      minWidth: "300px",
      "& fieldset": {
        border: "none",
      },
    },
    selectPopover: {
      marginTop: "15px",
      backgroundColor: theme.palette.appbar.main,
      color: theme.palette.background.default,
      "& .MuiMenu-list": {
        padding: "16px",
        "& .MuiMenuItem-root": {
          borderRadius: "8px",
          padding: "12px",
          "&:hover": {
            backgroundColor: alpha(theme.palette.appbar.light, 0.5),
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.appbar.light,
          },
        },
      },
    },

    select: {
      padding: "10px",
    },
  })
);

const StyledChip = styled(Chip)({
  backgroundColor: AppTheme.palette.success.dark,
  color: "white",
  marginLeft: "8px",
  fontWeight: "normal",
});

export interface AppBarItemType {
  index: number;
  label: string;
  path: string;
  hide?: boolean;
  external?: boolean;
  badgeCount?: number;
  isNew?: boolean;
  isBeta?: boolean;
  itemKey?: AppBarItemKey;
  navigateWithHref?: boolean;
  icon?: React.ReactNode;
  isPinned?: boolean;
}

export const PageLabelWithIcon = ({ appBarItem }: { appBarItem: AppBarItemType }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      {appBarItem?.icon && (
        <AppBarNextIcon Icon={appBarItem?.icon as unknown as React.ComponentType<SvgIconProps>} />
      )}
      <Typography variant="body1">{appBarItem.label}</Typography>
    </Box>
  );
};

type Props = {
  appBarItems: AppBarItemType[];
};

export const AppBarNextDropdown: React.FC<Props> = ({ appBarItems }) => {
  const classes = useStyles();
  const location = useLocation();
  const initialItem = appBarItems.find((item) =>
    (location.pathname + location.search).includes(item.path)
  );
  const [value, setValue] = useState(initialItem?.index);
  const [pinnedPages, setPinnedPages] = useLocalStorage<string[]>(
    PINNED_PAGES_LOCAL_STORAGE_KEY,
    []
  );

  const handleChange = (event: SelectChangeEvent<number>, _child?: React.ReactNode) => {
    setValue(Number(event.target.value));
  };

  const navigateTo = (appBarItem: AppBarItemType) => (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();

    // Check if Command (metaKey) or Control (ctrlKey) is pressed.
    if (event.ctrlKey || event.metaKey) {
      if (appBarItem.navigateWithHref) {
        const selectedLanguage = getSelectedLanguage();
        window.open(`/${selectedLanguage}/search`, "_blank");
      } else {
        window.open(appBarItem.path, "_blank");
      }
    } else {
      // Update the selection as before
      handleChange({
        target: {
          value: appBarItem.index,
        },
      } as SelectChangeEvent<number>);

      if (appBarItem.navigateWithHref) {
        const selectedLanguage = getSelectedLanguage();
        window.location.href = `/${selectedLanguage}/search`;
      } else {
        history.push(appBarItem.path);
      }
    }
  };

  const togglePin = (e, appBarItem: AppBarItemType) => {
    e.stopPropagation();
    const pinnedPagesArray = pinnedPages || [];
    const isPinned = pinnedPagesArray.includes(appBarItem.itemKey);
    if (isPinned) {
      const updatedPinnedPages = pinnedPagesArray.filter((key) => key !== appBarItem.itemKey);
      setPinnedPages(updatedPinnedPages);
    } else {
      if (pinnedPages.length === 3) {
        snackbarUtils.custom(t("mainNavigationSidebar:maxPinnedPages"), undefined);
        return;
      }
      pinnedPagesArray.push(appBarItem.itemKey);
      setPinnedPages(pinnedPagesArray);
    }
  };

  return (
    <Select
      onChange={handleChange}
      classes={{
        root: classes.selectRoot,
        select: classes.select,
      }}
      value={value}
      IconComponent={KeyboardArrowDownIcon}
      renderValue={(selected) => {
        const selectedItem = appBarItems.find((item) => item.index === selected);
        return selectedItem ? <PageLabelWithIcon appBarItem={selectedItem} /> : "";
      }}
      MenuProps={{
        PaperProps: {
          className: classes.selectPopover,
        },
      }}
    >
      {appBarItems?.map((appBarItem, ix) => {
        if (appBarItem?.external) {
          return <ExternalLinkActionItem key={ix} appBarItem={appBarItem} togglePin={togglePin} />;
        }

        return (
          <MenuItem
            sx={{
              display: appBarItem?.hide ? "none" : "default",
              position: "relative",
              "&:hover .hoverIcon": {
                display: "inline-flex",
              },
            }}
            key={ix}
            value={appBarItem?.index}
            onClick={navigateTo(appBarItem)}
          >
            <Box width={"100%"} display="flex" justifyContent={"space-between"} alignItems="center">
              <PageLabelWithIcon appBarItem={appBarItem} />
              <Box display="flex" alignItems="center">
                {appBarItem?.badgeCount > 0 && (
                  <Badge color="primary" badgeContent={appBarItem?.badgeCount} sx={{ mr: 1 }} />
                )}
                {appBarItem?.isNew && (
                  <Chip
                    color="primary"
                    className={classes.chipStyle}
                    label={t("mainNavigationSidebar:new")}
                  />
                )}
                {appBarItem?.isBeta && <StyledChip size="small" label="Beta" />}
                <Box
                  className="hoverIcon"
                  sx={{ display: appBarItem?.isPinned ? "inline-flex" : "none", ml: 1 }}
                >
                  <Tooltip
                    title={
                      appBarItem?.isPinned
                        ? t("mainNavigationSidebar:unpin")
                        : t("mainNavigationSidebar:pin")
                    }
                  >
                    <IconButton
                      size="small"
                      sx={{ padding: 0 }}
                      onClick={(e) => togglePin(e, appBarItem)}
                    >
                      {appBarItem?.isPinned ? (
                        <StarIcon sx={{ color: "white" }} />
                      ) : (
                        <StarOutlineIcon sx={{ color: "white" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};
