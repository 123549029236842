import { Box, IconButton, Link, Tooltip, alpha } from "@mui/material";
import React from "react";
import { AppBarItemType, PageLabelWithIcon } from "../../app-bar-next-dropdown";
import { useTranslation } from "react-i18next";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { AppThemeV5 } from "layout/AppTheme";

type Props = {
  appBarItem: AppBarItemType;
  togglePin: (e: any, appBarItem: AppBarItemType) => void;
};

export const ExternalLinkActionItem: React.FC<Props> = ({ appBarItem, togglePin }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ cursor: "pointer" }}
      padding="12px"
      width={"100%"}
      sx={{
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: alpha(AppThemeV5.palette.appbar.light, 0.5),
        },
        "&:hover .hoverIcon": {
          display: "inline-flex",
        },
      }}
    >
      <Link
        href={appBarItem.path}
        target={"_blank"}
        style={{ textDecoration: "none", color: "white", width: "100%" }}
      >
        <PageLabelWithIcon appBarItem={appBarItem} />
      </Link>
      <Box
        className="hoverIcon"
        sx={{ display: appBarItem?.isPinned ? "inline-flex" : "none", ml: 1 }}
      >
        <Tooltip
          title={
            appBarItem?.isPinned ? t("mainNavigationSidebar:unpin") : t("mainNavigationSidebar:pin")
          }
        >
          <IconButton size="small" sx={{ padding: 0 }} onClick={(e) => togglePin(e, appBarItem)}>
            {appBarItem?.isPinned ? (
              <StarIcon sx={{ color: "white" }} />
            ) : (
              <StarOutlineIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
