import { RootState } from "store";
import { FetchRemovedAddedSpecsOfScopeResponse, MessageThread } from "./types";

// HELPER FUNCTION: extracts active visible step data.
const getActiveVisibleStepData = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  if (!currentQuestionnaire || !currentQuestionnaire.steps) {
    return { activeStep: null, visibleSteps: [], activeFormStepIndex: -1 };
  }
  const visibleSteps = currentQuestionnaire.steps.filter((step) => step.is_visible);
  const activeFormStepIndex = visibleSteps.findIndex((step) => step.is_active);
  const activeStep = activeFormStepIndex >= 0 ? visibleSteps[activeFormStepIndex] : null;
  return { activeStep, visibleSteps, activeFormStepIndex };
};

export const selectFormData = (state: RootState) => state.vendorManagementNext.formData;
export const selectFormDataLoading = (state: RootState) =>
  state.vendorManagementNext.formDataLoading;
export const selectFormDataError = (state: RootState) => state.vendorManagementNext.formDataError;

export const selectQuestionnaireName = (state: RootState) =>
  state.vendorManagementNext?.currentQuestionnaireName;

export const selectIsFormStepCompleted = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.is_completed : false;
};

export const selectIsFormStepInReview = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.approval_status === "changes_requested" : false;
};

export const selectIsFormStepApproved = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.approval_status === "approved" : false;
};

export const selectFormApprovedBy = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.approved_by : null;
};

export const selectFormApprovedAt = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.approved_at : null;
};

export const selectIsFormStepApprovalStatusChangesDone = (state: RootState) => {
  const { activeStep } = getActiveVisibleStepData(state);
  return activeStep ? activeStep.approval_status === "changes_done" : false;
};

export const selectIsLastStep = (state: RootState) => {
  const { visibleSteps, activeFormStepIndex } = getActiveVisibleStepData(state);
  return activeFormStepIndex === visibleSteps.length - 1;
};

export const selectSteps = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  return currentQuestionnaire.steps;
};
export const selectStepsLoading = (state: RootState) =>
  state.vendorManagementNext.fetchStepsLoading;
export const selectStepsError = (state: RootState) => state.vendorManagementNext.fetchStepsError;

export const selectProcessControlStandards = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsData;
export const selectProcessControlStandardsLoading = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsLoading;
export const selectProcessControlStandardsError = (state: RootState) =>
  state.vendorManagementNext.processControlStandardsError;

export const selectAllSpecifications = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsData;
export const selectAllSpecificationsLoading = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsLoading;
export const selectAllSpecificationsError = (state: RootState) =>
  state.vendorManagementNext.allSpecificationsError;

export const selectDeleteProcessControlStandardLoading = (state: RootState) =>
  state.vendorManagementNext.deleteProcessControlStandardLoading;

export const selectAclpClassificationsLoading = (state: RootState) =>
  state.vendorManagementNext.aclpClassificationsLoading;
export const selectAclpClassifications = (state: RootState) =>
  state.vendorManagementNext.aclpClassificationsData;

export const selectSupplierCategoriesLoading = (state: RootState) =>
  state.vendorManagementNext.supplierCategoriesLoading;
export const selectSupplierCategories = (state: RootState) =>
  state.vendorManagementNext.supplierCategoriesData;

export const selectSpecsOfScopeLoading = (state: RootState) =>
  state.vendorManagementNext.specsOfScopeLoading;
export const selectSpecsOfScopeError = (state: RootState) =>
  state.vendorManagementNext.specsOfScopeError;
export const selectSpecsOfScope = (state: RootState) => state.vendorManagementNext.specsOfScopeData;

export const selectRemovedSpecsOfScope = (state: RootState) => ({
  isLoading: state.vendorManagementNext.removedSpecsOfScopeLoading as boolean,
  error: state.vendorManagementNext.removedSpecsOfScopeError as Error,
  data: state.vendorManagementNext.removedSpecsOfScopeData as FetchRemovedAddedSpecsOfScopeResponse,
});

export const selectAddedSpecsOfScope = (state: RootState) => ({
  isLoading: state.vendorManagementNext.addedSpecsOfScopeLoading as boolean,
  error: state.vendorManagementNext.addedSpecsOfScopeError as Error,
  data: state.vendorManagementNext.addedSpecsOfScopeData as FetchRemovedAddedSpecsOfScopeResponse,
});

export const selectRemoveSpecFromScopeLoading = (state: RootState) =>
  state.vendorManagementNext.removeSpecFromScopeLoading;
export const selectAddSpecToScopeLoading = (state: RootState) =>
  state.vendorManagementNext.addSpecToScopeLoading;

export const selectSubtierCompaniesLoading = (state: RootState) =>
  state.vendorManagementNext.subtierCompaniesLoading;
export const selectSubtierCompanies = (state: RootState) =>
  state.vendorManagementNext.subtierCompaniesData?.results;
export const selectSpecsOfSubtierSupplierLoading = (state: RootState) =>
  state.vendorManagementNext.specsOfSubtierSupplierLoading;
export const selectSpecsOfSubtierSupplier = (supplier_id: string) => (state: RootState) => {
  return (
    state.vendorManagementNext?.specsOfSubtierSuppliersData?.find(
      (supplier) => supplier.supplier_id === supplier_id
    )?.rows || []
  );
};
export const selectAllSpecsOfSubtierSupppliers = (state: RootState) =>
  state.vendorManagementNext.specsOfSubtierSuppliersData;
export const selectSubtierOnboardingTableDataLoading = (state: RootState) =>
  state.vendorManagementNext.subtierOnboardingTableDataLoading;
export const selectSubtierOnboardingTableData = (state: RootState) =>
  state.vendorManagementNext.subtierOnboardingTableData;
export const selectDesignAndBuiltDrawingsLoading = (state: RootState) =>
  state.vendorManagementNext.designAndBuiltDrawingsLoading;
export const selectDesignAndBuiltDrawings = (state: RootState) =>
  state.vendorManagementNext.designAndBuiltDrawingsData;

export const selectIsLoadingSpecsOfLeadTimeForProduction = (state: RootState) =>
  state.vendorManagementNext.specsOfLeadTimeForProductionLoading;
export const selectSpecsOfLeadTimeForProduction = (state: RootState) =>
  state.vendorManagementNext.specsOfLeadTimeForProductionData;

export const selectIsLoadingSpecsOfBufferStockStrategy = (state: RootState) =>
  state.vendorManagementNext.specsOfBufferStockStrategyLoading;
export const selectSpecsOfBufferStockStrategy = (state: RootState) =>
  state.vendorManagementNext.specsOfBufferStockStrategyData;

export const selectIsLoadingSuppliers = (state: RootState) =>
  state.vendorManagementNext.suppliersLoading;
export const selectSuppliers = (state: RootState) => state.vendorManagementNext.suppliersData;

export const selectedCompanyDetails = (state: RootState) =>
  state.vendorManagementNext.supplierCompanyDetails;

export const selectIsLoadingIdentificationData = (state: RootState) =>
  state.vendorManagementNext.identificationDataLoading;
export const selectIdentificationData = (state: RootState) =>
  state.vendorManagementNext.identificationData;

export const selectCompletionStatusesOfSteps = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  const steps = currentQuestionnaire?.steps.filter((step) => step.is_visible);
  return steps.map((step) => step.is_completed);
};

export const selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  const numberOfCompletedSteps = currentQuestionnaire?.steps.filter(
    (step) => step.is_completed && step.is_visible
  ).length;
  const visibleSteps = currentQuestionnaire.steps.filter((step) => step.is_visible);

  return numberOfCompletedSteps === visibleSteps.length - 1;
};

export const selectIsAllStepsCompleted = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  const numberOfCompletedSteps = currentQuestionnaire?.steps.filter(
    (step) => step.is_completed && step.is_visible
  ).length;
  const visibleSteps = currentQuestionnaire.steps.filter((step) => step.is_visible);

  return numberOfCompletedSteps === visibleSteps.length;
};

export const selectIsAllStepsAreNotInReviewInitially = (state: RootState) => {
  const steps =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName].steps;
  const isAllStepsAreNotInReview = steps.every(
    (step) => step.approval_status === null || step.approval_status === "approved"
  );

  return isAllStepsAreNotInReview;
};

export const selectIsAllStepsApprovalNull = (state: RootState) => {
  const steps =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName].steps;
  const isAllStepsAreNotInReview = steps.every((step) => step.approval_status === null);

  return isAllStepsAreNotInReview;
};

export const selectIsThereAnyStepInReviewProcess = (state: RootState) => {
  const steps =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName].steps;
  const isThereAnyStepInReview = steps.some(
    (step) =>
      step.approval_status === "changes_requested" ||
      step.approval_status === "changes_done" ||
      step.approval_status === "approved"
  );

  return isThereAnyStepInReview;
};

export const selectActiveFormStepIndex = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];

  return currentQuestionnaire.activeFormStepIndex;
};

export const selectLastSavedDate = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  return currentQuestionnaire.lastSavedDate;
};

export const selectIsThereUnsavedChangesInFormStep = (state: RootState) => {
  return state.vendorManagementNext.isThereUnsavedChangesInFormStep;
};

export const selectVisibleSteps = (state: RootState) => {
  const currentQuestionnaire =
    state.vendorManagementNext[state.vendorManagementNext.currentQuestionnaireName];
  return currentQuestionnaire.steps.filter((step) => step.is_visible);
};

export const isSendSupportMessageInProgress = (state: RootState) => {
  return state.vendorManagementNext.sendingSupportMessageLoading;
};

// for validation management
export const selectQuestionnairesDataLoading = (state: RootState) =>
  state.vendorManagementNext.fetchQuestionnairesLoading;
export const selectQuestionnairesData = (state: RootState) =>
  state.vendorManagementNext.questionnairesData;

export const selectIsFormDisplayForValidationManagement = (state: RootState) =>
  state.vendorManagementNext.isFormDisplayForValidationManagement;

export const selectMessageThreadsOfStepLoading = (state: RootState) =>
  state.vendorManagementNext.fetchMessageThreadsOfStepLoading;
export const selectMessageThreadsOfStep = (state: RootState) =>
  state.vendorManagementNext.messageThreadsOfStepData;

export const selectMessagesOfThreadLoading = (state: RootState) =>
  state.vendorManagementNext.fetchMessagesOfThreadLoading;
export const selectMessagesOfThread = (state: RootState) =>
  state.vendorManagementNext.messagesOfThreadData;

export const selectTriggerForFetchingMessagesOfThread = (state: RootState) =>
  state.vendorManagementNext.triggerForFetchingMessagesOfThread;
export const selectCurrentMessageThread = (state: RootState): MessageThread =>
  state.vendorManagementNext.currentMessageThread;

export const selectIsSideDrawerOpen = (state: RootState) =>
  state.vendorManagementNext.isSideDrawerOpen;

export const selectIsShowReviewBoxForThreadfulReview = (state: RootState) =>
  state.vendorManagementNext.isShowReviewBoxForThreadfulReview;

export const selectIsInternalNotesDrawerOpen = (state: RootState) =>
  state.vendorManagementNext.isInternalNotesDrawerOpen;

export const selectTriggerForFetchingSpecsOfStep = (state: RootState) =>
  state.vendorManagementNext.triggerForFetchingSpecsOfStep;

export const selectNumberOfUnapprovedLines = (state: RootState) =>
  state.vendorManagementNext.numberOfUnapprovedLines;

export const selectIsFetchSpecByClickedThread = (state: RootState) =>
  state.vendorManagementNext.isFetchSpecByClickedThread;
export const selectNameOfTableToFetchSpecByClickedThread = (state: RootState) =>
  state.vendorManagementNext.nameOfTableToFetchSpecByClickedThread;

// for readiness dashboard
export const selectReadinessDashboardDataLoading = (state: RootState) =>
  state.vendorManagementNext.readinessDashboardDataLoading;
export const selectReadinessDashboardDataRequestError = (state: RootState) =>
  state.vendorManagementNext.readinessDashboardDataError;
export const selectReadinessDashboardData = (state: RootState) =>
  state.vendorManagementNext.readinessDashboardData;
export const selectSupplierFilter = (state: RootState) => state.vendorManagementNext.supplierFilter;

export const selectSuppliersTableDataLoading = (state: RootState) =>
  state.vendorManagementNext.suppliersTableDataLoading;
export const selectSuppliersTableData = (state: RootState) =>
  state.vendorManagementNext.suppliersTableData;

export const selectSpecsTableDataLoading = (state: RootState) =>
  state.vendorManagementNext.specsTableDataLoading;
export const selectSpecsTableData = (state: RootState) => state.vendorManagementNext.specsTableData;

export const selectInternalNotes = (state: RootState) => state.vendorManagementNext.internalNotes;
export const selectHasInternalNotes = (state: RootState) => {
  return Boolean(state.vendorManagementNext.internalNotes.data.notes);
};
export const selectIsExtractingDataLoading = (state: RootState) =>
  state.vendorManagementNext.specsExportDataLoading;
