import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import PaginatedList from "@next/components/paginated-list";
import { formatDate } from "helpers/formatDate";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "../../redux";
import {
  selectDraftRFQs,
  selectDraftRFQsCount,
  selectFetchDraftRFQsLoading,
  selectLoadDraftRFQLoading,
} from "../../redux/selectors";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import { CustomTypography } from "@next/components/custom-typography";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      display: "flex",
      alignItems: "center",
    },
    rfqName: {
      marginRight: theme.spacing(0.75),
    },
    empty: {
      padding: theme.spacing(0.75, 2),
      minWidth: 160,
      display: "flex",
      alignItems: "center",
    },
  })
);

interface DraftRFQsListProps {
  onItemClick: (item: any) => void;
  projectCode?: string;
}

export const DraftRFQsList: React.FC<DraftRFQsListProps> = ({ onItemClick, projectCode }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const draftRfqs = useSelector(selectDraftRFQs);
  const draftsCount = useSelector(selectDraftRFQsCount);
  const page = useRef(2);
  const loading = useSelector(selectFetchDraftRFQsLoading);
  const activeDraftLoading = useSelector(selectLoadDraftRFQLoading);

  const onEndReached = useCallback(() => {
    if (draftRfqs.length >= draftsCount) return;
    dispatch(
      workspaceNextActions.fetchDraftRFQsRequest({
        query: { page: page.current, project: projectCode },
      })
    );
    page.current++;
  }, [draftRfqs.length, draftsCount, page.current, projectCode]);

  return (
    <PaginatedList
      data={draftRfqs}
      onEndReached={onEndReached}
      loading={loading}
      renderItem={(item) => (
        <MenuItem onClick={() => onItemClick(item)}>
          <Box className={classes.menuItem}>
            <Box mr={2}>
              {activeDraftLoading === item.id ? (
                <CircularProgress sx={{ mt: 1 }} size={16} />
              ) : (
                <EditOutlinedIcon />
              )}
            </Box>
            <CustomTypography maxWidth={200} className={classes.rfqName} variant="body1">
              {item.data?.rfq_name || t("workspaceNext:rfqDrafts:untitled")}
            </CustomTypography>
            <Typography variant="body1" color="textSecondary">
              {t("workspaceNext:rfqDrafts:lastSaved")} {formatDate(item.updated_at)}
            </Typography>
          </Box>
        </MenuItem>
      )}
      EmptyContent={
        <Box className={classes.empty}>
          <Typography variant="body1">{t("workspaceNext:rfqDrafts:noDrafts")}</Typography>
        </Box>
      }
    />
  );
};
