import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormFooter } from "../../form-footer/form-footer";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { FormFieldBox } from "../../form-field-box";
import { FormTitle } from "../../form-title/form-title";
import { FormBoxMd } from "../../form-box/form-box-md";
import { FormParentBox } from "../../form-parent-box";
import { FormBoxWrapper } from "../../form-box/form-box-wrapper";
import { SideDrawer } from "../../side-drawer/side-drawer";
import {
  FieldMandatoryText,
  IdentificationFormData,
  PartialStep,
  PosEndUsersFormKeyMapping,
  Questionnaire,
  QuestionnaireForms,
  Step,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectAclpClassifications,
  selectFormApprovedAt,
  selectFormApprovedBy,
  selectFormData,
  selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepApproved,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectIsInternalNotesDrawerOpen,
  selectIsLastStep,
  selectIsSideDrawerOpen,
  selectIsThereAnyStepInReviewProcess,
  selectIsThereUnsavedChangesInFormStep,
  selectQuestionnaireName,
  selectSteps,
  selectSupplierCategories,
  selectVisibleSteps,
  selectedCompanyDetails,
} from "@next/modules/vendor-management/redux/selectors";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { modalsActions } from "@next/redux/modalsSlices";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import { showThreadsNeedToBeResolvedMessage } from "@next/modules/vendor-management/utils/showThreadsNeedToBeResolvedMessage";
import * as S from "./identification-form.styled";
import { FormFieldInitValue } from "../../form-field-init-value";

export const IdentificationForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const dispatch = useDispatch();
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const isFormApproved = useSelector(selectIsFormStepApproved);
  const formApprovedAt = useSelector(selectFormApprovedAt);
  const formApprovedBy = useSelector(selectFormApprovedBy);
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompletedExceptBeforeCurrentStepCompletion = useSelector(
    selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion
  );
  const isThereAnyStepInReview = useSelector(selectIsThereAnyStepInReviewProcess);
  const isFormFrozen = isFormApproved || isFormDisplayForValidationManagement;
  const allSteps = useSelector(selectSteps);
  const visibleSteps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(visibleSteps);
  const activeFormStep: Step = visibleSteps[activeFormStepIndex];
  const questionnaireName = useSelector(selectQuestionnaireName);
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const isInternalNotesDrawerOpen = useSelector(selectIsInternalNotesDrawerOpen);

  const aclpClassificationOptions = useSelector(selectAclpClassifications);
  const supplierCategoryOptions = useSelector(selectSupplierCategories);
  const identificationFormDataState = useSelector(selectFormData) as IdentificationFormData;
  const initialData = useSelector(selectedCompanyDetails)?.initial_data || {};

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useForm<IdentificationFormData>({
    defaultValues: identificationFormDataState,
  });

  useEffect(() => {
    dispatch(vendorManagementActions.fetchAclpClassificationsRequest());
    dispatch(vendorManagementActions.fetchSupplierCategoriesRequest());
  }, []);

  const save = (isForCompletion: boolean) => {
    const formValues = watch();

    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.Identification,
        companyId: companyId,
      })
    );

    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
  };

  const saveChanges = (isForCompletion: boolean) => {
    save(isForCompletion);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<IdentificationFormData> = (data) => {
    const formValues = watch();
    const isHaveSubtier = formValues.has_subtiers;

    // don't allow to complete step if there are unresolved threads
    if (activeFormStep?.unresolved_threads_count !== 0) {
      showThreadsNeedToBeResolvedMessage();
      return;
    }

    if (questionnaireName === Questionnaire.PosEndUsers) {
      if (isHaveSubtier !== null && isHaveSubtier !== undefined) {
        makeFormStepCompletionProcess();
      } else {
        setError("has_subtiers", { type: "manual" });
        showInvalidMessage();
      }
    } else {
      makeFormStepCompletionProcess();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompletedExceptBeforeCurrentStepCompletion) {
      showThankYouModal();
    } else if (!isLastStep && !isThereAnyStepInReview) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = visibleSteps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_done")
            : key === "is_completed" && !value
              ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
              : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (
    statusParam: "changes_done" | "changes_requested"
  ) => {
    if (
      isFormInReview &&
      activeFormStep?.unresolved_threads_count === 0 &&
      statusParam === "changes_done"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    } else if (
      activeFormStep?.approval_status === "changes_done" &&
      statusParam === "changes_requested"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  const onInvalid = () => {
    const formValues = watch();
    if (questionnaireName === Questionnaire.PosEndUsers) {
      const isHaveSubtier = formValues.has_subtiers; // extra validation for isHaveSubtier
      if (isHaveSubtier === null || isHaveSubtier === undefined) {
        setError("has_subtiers", { type: "manual" });
      }
    }
    showInvalidMessage();
  };

  const handleSubtierChange = (value: boolean) => {
    const subtierFormStep = allSteps.find(
      (step) => step.step_name === PosEndUsersFormKeyMapping.Subtiers
    );
    const subtierOnboardingFormStep = allSteps.find(
      (step) => step.step_name === PosEndUsersFormKeyMapping.SubtiersOnboarding
    );

    if (subtierFormStep && subtierOnboardingFormStep) {
      const partialStepOfSubtier: PartialStep = {
        is_visible: value,
      };
      const partialStepOfSubtierOnboarding: PartialStep = {
        is_visible: value,
      };

      dispatch(
        vendorManagementActions.updateStepRequest({
          stepId: subtierFormStep.id,
          data: partialStepOfSubtier,
        })
      );
      dispatch(
        vendorManagementActions.updateStepRequest({
          stepId: subtierOnboardingFormStep.id,
          data: partialStepOfSubtierOnboarding,
        })
      );
    }
    save(false); // user can change subtier value without saving the form, if it's not saved, causes bug for subtier steps
    removeCompleteStatusOfStep();
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  const removeCompleteStatusOfStep = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
    }
  };

  return (
    <>
      <FormParentBox>
        <FormBoxWrapper isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}>
          <FormBoxMd
            isCompleted={isFormCompleted}
            isApproved={isFormApproved}
            isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
            isDrawerOpen={isDrawerOpen || isInternalNotesDrawerOpen}
          >
            <FormTitle
              title={"Identification"}
              isCompleted={isFormCompleted}
              isApproved={isFormApproved}
              isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
            />

            <FormControl margin="none">
              <Controller
                name="title"
                control={control}
                defaultValue={identificationFormDataState?.title || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Company title" />
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                    {errors.title ? <FormFieldErrorTypography text={FieldMandatoryText} /> : null}
                    <FormFieldInitValue value={initialData?.title} />
                  </FormFieldBox>
                )}
              />
            </FormControl>

            <FormControl margin="none">
              <Controller
                name="address"
                control={control}
                defaultValue={identificationFormDataState?.address || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Company address" />
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                    {errors.address ? <FormFieldErrorTypography text={FieldMandatoryText} /> : null}
                    <FormFieldInitValue value={initialData?.address} />
                  </FormFieldBox>
                )}
              />
            </FormControl>

            <FormControl margin="none">
              <Controller
                name="manufacturing_site_address"
                control={control}
                defaultValue={identificationFormDataState?.manufacturing_site_address || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Manufacturing site address" />
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                    {errors.manufacturing_site_address ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                  </FormFieldBox>
                )}
              />
            </FormControl>

            <FormControl margin="none">
              <Controller
                name="vendor_code"
                control={control}
                defaultValue={identificationFormDataState?.vendor_code || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Company vendor code" />
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                    {errors.vendor_code ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                    <FormFieldInitValue value={initialData["vendor code"]} />
                  </FormFieldBox>
                )}
              />
            </FormControl>

            <FormControl margin="none">
              <Controller
                name="cage_code"
                control={control}
                defaultValue={identificationFormDataState?.cage_code || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Company cage code" />
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                    {errors.cage_code ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                  </FormFieldBox>
                )}
              />
            </FormControl>

            <FormControl fullWidth margin="none">
              <FormFieldBox>
                <FormFieldLabel label="ACLP classification" />
                <Controller
                  name="classification"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={identificationFormDataState?.classification || []}
                  render={({ field }) => {
                    return (
                      <>
                        <Select
                          multiple
                          {...field}
                          value={field?.value || []}
                          onChange={(e) => {
                            field.onChange(e);
                            checkInputChange();
                          }}
                          error={!!errors.classification}
                          readOnly={isFormFrozen}
                        >
                          {aclpClassificationOptions
                            ? aclpClassificationOptions?.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                        <FormFieldInitValue value={initialData?.classification} />
                      </>
                    );
                  }}
                />
              </FormFieldBox>
            </FormControl>

            <FormControl fullWidth margin="none">
              <FormFieldBox>
                <FormFieldLabel label="Supplier category, as per QMS-09-01." />
                <Controller
                  name="supplier_category"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={identificationFormDataState?.supplier_category || null}
                  render={({ field }) => {
                    return (
                      <>
                        <Select
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e);
                            checkInputChange();
                          }}
                          error={!!errors.supplier_category}
                          readOnly={isFormFrozen}
                        >
                          {supplierCategoryOptions
                            ? supplierCategoryOptions.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option?.id}>
                                    {option.name}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                        <S.StyledFormHelperText>
                          DIRECT = Direct ordering from AIRBUS CANADA
                        </S.StyledFormHelperText>
                        <FormFieldInitValue value={initialData["supplier category"]} />
                      </>
                    );
                  }}
                />
              </FormFieldBox>
            </FormControl>

            <FormControl margin="none">
              <Controller
                name="email_points_of_contact"
                control={control}
                defaultValue={identificationFormDataState?.email_points_of_contact || ""}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <FormFieldLabel label="Points of contact, add one line per contact, add email and job" />
                    <TextField
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                        inputComponent: TextareaAutosize,
                        inputProps: {
                          minRows: 1,
                        },
                      }}
                    />
                    {errors.email_points_of_contact ? (
                      <FormFieldErrorTypography text={FieldMandatoryText} />
                    ) : null}
                    <FormFieldInitValue value={initialData?.["contacts"]} />
                  </FormFieldBox>
                )}
              />
            </FormControl>

            {questionnaireName === Questionnaire.PosEndUsers ? (
              <FormControl fullWidth margin="none" error={errors.has_subtiers ? true : false}>
                <FormFieldBox>
                  <FormFieldLabel label="Do you have subtiers?" />

                  <Controller
                    name="has_subtiers"
                    control={control}
                    render={({ field }) => (
                      <>
                        <S.StyledRadioGroup
                          {...field}
                          value={field?.value === null ? "" : field.value}
                          onChange={(e) => {
                            const value = e.target.value === "true" ? true : false;
                            field.onChange(value);
                            handleSubtierChange(value);
                          }}
                        >
                          <S.StyledFormControlLabel
                            value="true"
                            control={<S.StyledRadio />}
                            label="Yes"
                            disabled={isFormFrozen}
                          />
                          <S.StyledFormControlLabel
                            value="false"
                            control={<S.StyledRadio />}
                            label="No"
                            disabled={isFormFrozen}
                          />
                        </S.StyledRadioGroup>
                        {errors.has_subtiers ? (
                          <FormFieldErrorTypography text={FieldMandatoryText} />
                        ) : null}
                      </>
                    )}
                  />
                </FormFieldBox>
              </FormControl>
            ) : null}

            <FormControl margin="none">
              <Controller
                name="additional_comment"
                control={control}
                defaultValue={identificationFormDataState?.additional_comment || ""}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <FormFieldBox>
                    <TextField
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      placeholder="Additional comment..."
                      multiline
                      rows={3}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                  </FormFieldBox>
                )}
              />
            </FormControl>
          </FormBoxMd>
        </FormBoxWrapper>

        <SideDrawer isThreadful={false} />
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
        isApproved={isFormApproved}
        isInReview={isFormInReview && activeFormStep?.unresolved_threads_count !== 0}
        isShowReviewButton={true}
        approvedAt={formApprovedAt}
        approvedBy={formApprovedBy}
      />
    </>
  );
};
