import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { modalsActions } from "@next/redux/modalsSlices";
import { AirbusSpecificationTable } from "../../../table/airbus-specification-table/airbus-specification-table";
import { FormAddButton } from "../../../form-add-button/form-add-button";
import { FormFieldErrorTypography } from "../../../form-field-error-typography/form-field-error-typography";
import { AddSpecListModal } from "../../../modals/add-spec-list-modal/add-spec-list-modal";
import { TableLinesBox } from "../../../table-lines-box/table-lines-box";
import { InfoTitle } from "../../../form-field-label/form-field-label";
import {
  A2psThreadLabel,
  FieldMandatoryText,
  PaginationSizeForFormTable,
  PartialStep,
  SpecificationTableRow,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectCurrentMessageThread,
  selectFormData,
  selectIsFetchSpecByClickedThread,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectNameOfTableToFetchSpecByClickedThread,
  selectSpecsOfScope,
  selectSpecsOfScopeLoading,
  selectTriggerForFetchingSpecsOfStep,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import * as S from "./add-airbus-specification-field.styled";
import useTableFilterAndSort from "@next/hooks/useTableFilterAndSort-v5";
import { getAirbusSpecificationSortQueryString } from "../../../table/airbus-specification-table/airbus-specification-table.helpers";

type Props = {
  isShowTableErrorsIfExist: boolean;
  isTableDisabled: boolean;
};

const addSpecBtnLabel =
  "If there a missing specifications for process control, please add them from the list.";

export const AddAirbusSpecificationField: React.FC<Props> = ({
  isShowTableErrorsIfExist,
  isTableDisabled,
}) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const formData = useSelector(selectFormData);
  const specTableData = useSelector(selectSpecsOfScope);
  const specTableRows = specTableData?.results?.data || [];
  const [page, setPage] = useState(1);
  const specTableRowsLoading = useSelector(selectSpecsOfScopeLoading);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const triggerStateForFetchingSpecs = useSelector(selectTriggerForFetchingSpecsOfStep);
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isFetchSpecByClickedThread = useSelector(selectIsFetchSpecByClickedThread);
  const nameOfTableToFetchSpecByClickedThread = useSelector(
    selectNameOfTableToFetchSpecByClickedThread
  );
  const [hideCompletedTableLines, setHideCompletedTableLines] = useState(false);
  const [hideApprovedTableLines, setHideApprovedTableLines] = useState(false);
  const specTypesForTableSpecs = ["A2ERD", "A2TS"];

  const { handleSortChange, sortQueryString, sortModel } = useTableFilterAndSort(
    getAirbusSpecificationSortQueryString
  );

  useEffect(() => {
    if (formData) {
      fetchSpecs();

      dispatch(
        vendorManagementActions.fetchNumberOfUnapprovedLinesOfStepRequest({
          companyId: companyId,
          stepId: step.step,
        })
      );
    }
  }, [
    page,
    hideCompletedTableLines,
    hideApprovedTableLines,
    sortQueryString,
    triggerStateForFetchingSpecs,
    isFetchSpecByClickedThread,
  ]);

  const fetchSpecs = () => {
    if (
      isFetchSpecByClickedThread &&
      nameOfTableToFetchSpecByClickedThread === A2psThreadLabel.ScopeSpecification
    ) {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId: companyId,
          specType: specTypesForTableSpecs,
          pagination: { page: 1, pageSize: PaginationSizeForFormTable },
          stepId: step.step,
          filter_completed: false,
          filter_approved: false,
          ordering: sortQueryString,
          entityId: currentMessageThread?.entity_id,
        })
      );
    } else {
      dispatch(
        vendorManagementActions.fetchSpecsOfScopeRequest({
          companyId: companyId,
          specType: specTypesForTableSpecs,
          pagination: { page: page, pageSize: PaginationSizeForFormTable },
          stepId: step.step,
          filter_completed: hideCompletedTableLines,
          filter_approved: hideApprovedTableLines,
          ordering: sortQueryString,
        })
      );
    }
  };

  const handleAddSpecToScope = (rows: SpecificationTableRow[]) => {
    dispatch(
      vendorManagementActions.addSpecToScopeRequest({
        companyId: companyId,
        rows,
        onSuccess: onAddSpecSuccess,
      })
    );
  };

  const onAddSpecSuccess = () => {
    reFetchTableDataToGetUpdatedCount();
    checkInputChange();
  };

  const reFetchTableDataToGetUpdatedCount = () => {
    dispatch(
      vendorManagementActions.fetchSpecsOfScopeRequest({
        companyId: companyId,
        specType: specTypesForTableSpecs,
        pagination: { page: page, pageSize: PaginationSizeForFormTable },
        stepId: step.step,
        filter_completed: hideCompletedTableLines,
        filter_approved: hideApprovedTableLines,
        isForFetchingToGetCountData: true,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (step?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    }
  };

  const onClickOpenAddSpecListModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.ADD_SPEC_LIST_MODAL,
      })
    );
  };

  return (
    <>
      <S.StyledBox>
        <Box>
          <S.StyledBox>
            <TableLinesBox
              completedCount={specTableData?.results?.completed_count}
              approvedCount={specTableData?.results?.approved_count}
              totalCount={specTableData?.results?.total_count}
              hideCompletedTableLines={hideCompletedTableLines}
              hideApprovedTableLines={hideApprovedTableLines}
              setHideApprovedTableLines={setHideApprovedTableLines}
              setHideCompletedTableLines={setHideCompletedTableLines}
              setPage={setPage}
              isFormDisplayForValidationManagement={isFormDisplayForValidationManagement}
            />
            {!isFormDisplayForValidationManagement ? <InfoTitle /> : null}

            <S.StyledTableWrapper>
              <AirbusSpecificationTable
                hideCompletedTableLines={hideCompletedTableLines}
                page={page}
                setPage={setPage}
                pageSize={PaginationSizeForFormTable}
                isTableDisabled={isTableDisabled}
                sortModel={sortModel}
                onSortModelChange={handleSortChange}
              />
            </S.StyledTableWrapper>
          </S.StyledBox>

          {!isFormDisplayForValidationManagement ? (
            <S.StyledInnerBox>
              <S.StyledBtnBox>
                <FormAddButton
                  btnText={"Add Airbus specification"}
                  onClick={onClickOpenAddSpecListModal}
                  disabled={
                    specTableRowsLoading ||
                    isTableDisabled ||
                    (isFetchSpecByClickedThread &&
                      nameOfTableToFetchSpecByClickedThread === A2psThreadLabel.ScopeSpecification)
                  }
                />
              </S.StyledBtnBox>

              <S.StyledTypography variant="body2">{addSpecBtnLabel}</S.StyledTypography>
            </S.StyledInnerBox>
          ) : null}
        </Box>

        {isShowTableErrorsIfExist && specTableRows.length === 0 ? (
          <FormFieldErrorTypography text={FieldMandatoryText} />
        ) : null}
      </S.StyledBox>

      {!isFormDisplayForValidationManagement ? (
        <AddSpecListModal
          rowsAlreadyAdded={specTableRows}
          handleAddSpecToFormTable={handleAddSpecToScope}
          title={"Spec list"}
        />
      ) : null}
    </>
  );
};
