import React from "react";
import { Box, Switch, Theme, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import { createStyles, makeStyles } from "@mui/styles";
import { AppThemeV5 } from "layout/AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      width: "100%",
    },
  })
);

interface Props {
  syncQuote: boolean;
  setSyncQuote: (value: boolean) => void;
}

const SyncQuotesToggle: React.FC<Props> = ({ syncQuote, setSyncQuote }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncQuote(event.target.checked);
  };

  return (
    <Box className={classes.root}>
      <Switch checked={syncQuote} onChange={handleChange} />

      <LoopRoundedIcon style={{ color: AppThemeV5.palette.text.secondary }} />

      <Typography>
        <Trans i18nKey="rfq:quotations:syncQuotesToggle" />
      </Typography>
    </Box>
  );
};

export default SyncQuotesToggle;
