import { Badge, Box, SvgIconProps, Theme, Tooltip } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useLocalStorage from "hooks/useLocalStorage";
import { PINNED_PAGES_LOCAL_STORAGE_KEY } from "./app-bar-next.definitions";
import { AppBarItemType } from "./app-bar-next-dropdown";
import { AppBarNextIcon } from "./app-bar-next-drawer";
import { useHistory, useLocation } from "react-router-dom";
import { AppThemeV5 } from "layout/AppTheme";
import { getSelectedLanguage } from "@next/utils/browserUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginLeft: "8px",
    },
    button: {
      backgroundColor: theme.palette.appbar.main,
      color: theme.palette.background.default,
      padding: "10px",
      cursor: "pointer",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: alpha(theme.palette.appbar.light, 0.5),
      },
    },
  })
);

type Props = {
  appBarItems: AppBarItemType[];
};

export const AppBarNextPinnedPages: React.FC<Props> = ({ appBarItems }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [pinnedPages] = useLocalStorage<string[]>(PINNED_PAGES_LOCAL_STORAGE_KEY, []);

  const selectedItem = appBarItems.find((item) =>
    (location.pathname + location.search).includes(item.path)
  );

  const navigateTo =
    (appBarItem: AppBarItemType, isExternal: boolean) => (event: React.MouseEvent) => {
      // Check if Command (metaKey) or Control (ctrlKey) is pressed.
      if (isExternal) {
        window.open(appBarItem.path, "_blank");
      } else {
        if (event.ctrlKey || event.metaKey) {
          if (appBarItem.navigateWithHref) {
            const selectedLanguage = getSelectedLanguage();
            window.open(`/${selectedLanguage}/search`, "_blank");
          } else {
            window.open(appBarItem.path, "_blank");
          }
        } else {
          if (appBarItem.navigateWithHref) {
            const selectedLanguage = getSelectedLanguage();
            window.location.href = `/${selectedLanguage}/search`;
          } else {
            history.push(appBarItem.path);
          }
        }
      }
    };

  return (
    <Box className={classes.container}>
      {pinnedPages.map((pinnedPage) => {
        const appBarItem = appBarItems.find((item) => item.itemKey === pinnedPage);
        return (
          <Tooltip title={appBarItem?.label}>
            <Box
              key={pinnedPage}
              onClick={(e) => navigateTo(appBarItem as AppBarItemType, appBarItem?.external)(e)}
              className={classes.button}
              style={{
                backgroundColor:
                  selectedItem?.itemKey === pinnedPage
                    ? AppThemeV5.palette.appbar.light
                    : "transparent",
              }}
            >
              <Badge color="primary" badgeContent={appBarItem?.badgeCount}>
                <AppBarNextIcon
                  Icon={appBarItem?.icon as unknown as React.ComponentType<SvgIconProps>}
                />
              </Badge>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};
