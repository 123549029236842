import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Badge, IconButton, Typography } from "@mui/material";
import { SideDrawerThreadfulReview } from "./side-drawer-threadful-review/side-drawer-threadful-review";
import { SideDrawerUnThreadfulReview } from "./side-drawer-unthreadful-review/side-drawer-unthreadful-review";
import {
  selectCurrentMessageThread,
  selectHasInternalNotes,
  selectIsFormDisplayForValidationManagement,
  selectIsInternalNotesDrawerOpen,
  selectIsShowReviewBoxForThreadfulReview,
  selectIsSideDrawerOpen,
  selectVisibleSteps,
} from "../../redux/selectors";
import { MessageThread, vendorManagementActions } from "../../redux";
import { getUser } from "services/profile/profile.selectors";
import { getActiveStepIndex } from "../../utils/getActiveStepIndex";
import { getCountLabel } from "../../utils/getCountLabel";
import * as S from "./side-drawer.styled";
import { EditSquareIcon, ForumIcon } from "assets/icons";
import { SideDrawerInternalNote } from "./side-drawer-internal-note/side-drawer-internal-note";

type Props = {
  isThreadful: boolean;
};

export const SideDrawer: React.FC<Props> = ({ isThreadful }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const isInternalNotesDrawerOpen = useSelector(selectIsInternalNotesDrawerOpen);
  const hasInternalNotes = useSelector(selectHasInternalNotes);

  const user = useSelector(getUser);
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep = steps[activeFormStepIndex];
  const currentMessageThread = useSelector(selectCurrentMessageThread);
  const isShowReviewBoxForThreadfulReview = useSelector(selectIsShowReviewBoxForThreadfulReview);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );

  useEffect(() => {
    if (isDrawerOpen) {
      dispatch(
        vendorManagementActions.fetchMessageThreadsOfStepRequest({
          companyId: companyId,
          stepId: activeStep.step,
          onSuccess: updateCurrentMessageThreadByApiIfShowingDetailOfThread,
        })
      );
      if (currentMessageThread && currentMessageThread.id) {
        dispatch(
          vendorManagementActions.fetchMessagesOfThreadRequest({
            messageThreadId: currentMessageThread.id,
          })
        );
      }
    }
  }, [isDrawerOpen]);

  const updateCurrentMessageThreadByApiIfShowingDetailOfThread = (
    messageThreads: MessageThread[]
  ) => {
    if (currentMessageThread && isShowReviewBoxForThreadfulReview) {
      const currentMessageThreadInMessageThreads = messageThreads.find(
        (thread) => thread.id === currentMessageThread?.id
      );
      if (currentMessageThreadInMessageThreads) {
        dispatch(
          vendorManagementActions.setCurrentMessageThread(currentMessageThreadInMessageThreads)
        );
      }
    }
  };

  const toggleDrawer = (newOpen: boolean) => {
    dispatch(vendorManagementActions.setIsDrawerOpen(newOpen));
  };

  const onClose = () => {
    toggleDrawer(false);
  };

  const onOpen = () => {
    toggleDrawer(true);
  };

  const toggleInternalNotesDrawer = () => {
    dispatch(vendorManagementActions.setIsInternalNotesDrawerOpen(!isInternalNotesDrawerOpen));
  };

  const handleFetchNotes = () => {
    if (isFormDisplayForValidationManagement) {
      dispatch(
        vendorManagementActions.fetchInternalNote({
          companyId,
          step: activeStep?.step,
        })
      );
    }
  };

  useEffect(handleFetchNotes, [activeStep?.step]);

  return (
    <>
      {!isDrawerOpen && !isInternalNotesDrawerOpen ? (
        <S.StyledBox>
          <S.StyledIconBox onClick={onOpen}>
            {activeStep?.unresolved_threads_count > 0 ? (
              <Badge
                badgeContent={getCountLabel(activeStep?.unresolved_threads_count)}
                color="primary"
              >
                <ForumIcon />
              </Badge>
            ) : (
              <ForumIcon />
            )}
          </S.StyledIconBox>
          {isFormDisplayForValidationManagement ? (
            <S.StyledIconBox onClick={toggleInternalNotesDrawer}>
              {hasInternalNotes ? (
                <Badge badgeContent={1} color="primary">
                  <EditSquareIcon />
                </Badge>
              ) : (
                <EditSquareIcon />
              )}
            </S.StyledIconBox>
          ) : null}
        </S.StyledBox>
      ) : null}

      <S.StyledDrawer
        anchor="right"
        open={isInternalNotesDrawerOpen}
        onClose={toggleInternalNotesDrawer}
        onOpen={toggleInternalNotesDrawer}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <S.StyledHeaderBox>
          <Typography variant="h6">Internal note</Typography>

          <IconButton onClick={toggleInternalNotesDrawer}>
            <S.StyledCloseIcon />
          </IconButton>
        </S.StyledHeaderBox>

        <SideDrawerInternalNote />
      </S.StyledDrawer>

      <S.StyledDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={onClose}
        onOpen={onOpen}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            style: { backgroundColor: "transparent" },
          },
        }}
      >
        <S.StyledHeaderBox>
          <Typography variant="h6">Comments</Typography>

          <IconButton onClick={onClose}>
            <S.StyledCloseIcon />
          </IconButton>
        </S.StyledHeaderBox>

        {isThreadful ? <SideDrawerThreadfulReview /> : <SideDrawerUnThreadfulReview />}
      </S.StyledDrawer>
    </>
  );
};
