/**
 * reducer.js:
 * this file is configuration reducer, A Redux reducer is just a JavaScript function.
 * It takes two parameters: the initial state and action
 *
 */

// partConfigurationConstants Exported
import { partConfigurationConstants } from "./index";
/**
 * Initial state of the app + architecture of what
 *  we want to have in our store inside authentication
 */
const initialState = {
  processes: null,
  certifications: null,
  sectors: null,
  machineTypes: null,
  materials: null,
  treatment: null,
  alloys: null,
  partDefaults: null,
  tags: null,
  isLastRFQPublic: false,
  isLoading: false,
  selectedFilters: {},
  rfqCancellationRelasons: null,
  rfqStatuses: null,
  part_extra_fields: null, //
  quotation_extra_fields_definition: null,
  quotation_extra_fields: null,
  netDs: null,
  rfqItnbReasons: [],
  todayRFQsCount: 0,
  erpConfiguration: null,
  catalogView: false,
};

/**
 * Cofiguration reducer: use this to connect all the initialState to backend
 *
 * @param {objects} state  initiale state
 * @param {objects} action   actions are  JavaScript objects it look like this
 *
 * { type: '',payload: {}} The type property drives how the state should change
 * and it's always required by Redux. The payload property instead describes what
 * should change, and might be omitted if you don't have new data to save in the store.
 */

export function partConfiguration(state = initialState, action) {
  switch (action.type) {
    //Configuration data Reducer
    case partConfigurationConstants.PART_CONFIGURATION_DATA_REQUEST:
      return { ...state, isLoading: true };
    case partConfigurationConstants.PART_CONFIGURATION_DATA_SUCCESS:
      const config = action.partConfigurationData;
      return {
        ...state,
        processes: config.processes,
        certifications: config.certifications,
        sectors: config.sectors,
        machineTypes: config.machineTypes,
        materials: config.materials,
        treatment: config.treatment,
        alloys: config.alloys,
        partDefaults: config.part_defaults,
        tags: config.tags,
        rfqCancellationRelasons: config.rfq_cancellation_reasons,
        isLastRFQPublic: !config.is_private_rfq_preference,
        isLoading: false,
        rfqStatuses: config.rfq_ordered_statuses,
        partExtraFields: config.part_extra_fields,
        quotationExtraFields: config.quotation_extra_fields,
        netDs: config.net_ds,
        rfqItnbReasons: config.rfq_itnb_reasons,
        buyersNetwork: config.buyers_network,
        pmPartsHiddenColumns: config.pm_parts_hidden_columns,
        announcements: config.announcements,
        reportsButtonAvailable: config.reports_button_available,
        todayRFQsCount: config.today_rfq_count,
        erpConfiguration: config.erp_configuration,
        catalogView: config.catalog_view,
      };

    case partConfigurationConstants.PART_CONFIGURATION_DATA_FAILURE:
      return { ...state, isLoading: false };
    case partConfigurationConstants.PART_CONFIGURATION_SELECTED_FILTERS:
      const { name, checked } = action.selectedFilters;
      const filterData = {
        ...state.selectedFilters,
        [name]: checked,
      };
      return {
        ...state,
        selectedFilters: filterData,
      };

    case partConfigurationConstants.SET_ERP_TYPE_LAST_SYNC_DATE:
      return {
        ...state,
        erpConfiguration: {
          ...state.erpConfiguration,
          erp_type: {
            ...state.erpConfiguration.erp_type,
            last_synchronization_date: action.last_synchronization_date,
          },
        },
      };
    default:
      return state;
  }
}
