import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import snackbarUtils from "@next/utils/snackbarUtils";

import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";
import {
  selectFieldsError,
  selectLoading,
  selectRegexTestResult,
} from "@next/modules/ai/redux/selectors";
import { RegexPayload, TestRegexPayload } from "@next/modules/ai/redux/types";
import { DialogTitle } from "@material-ui/core";

interface RegexDialogProps {
  field: Field;
  lineDetectorRegex: string;
  currentRegex: string | RegexPayload;
  selectedText: string;
  parserId: string;
  sampleId: number;
  onClose: () => void;
}

const RegexDialog: React.FC<RegexDialogProps> = ({
  field,
  lineDetectorRegex,
  currentRegex,
  selectedText,
  parserId,
  sampleId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const testResult = useSelector(selectRegexTestResult);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectFieldsError);

  const [regexType, setRegexType] = useState<
    "basic" | "multiline_match" | "multiline_match_fragment"
  >("basic");
  const [basicRegex, setBasicRegex] = useState<string>("");
  const [extractionPattern, setExtractionPattern] = useState<string>("");
  const [continuationPattern, setContinuationPattern] = useState<string | null>(null);
  const [lineDetectorPattern, setLineDetectorPattern] = useState<string | null>(null);
  const [delimiter, setDelimiter] = useState<string>("");
  const [valueToExtractPattern, setValueToExtractPattern] = useState<string>("");

  // Load the saved regex when the component is mounted or the currentRegex prop changes
  useEffect(() => {
    if (typeof currentRegex === "string") {
      setRegexType("basic");
      setBasicRegex(currentRegex);
    } else if (typeof currentRegex === "object") {
      setRegexType(currentRegex.type);
      if (currentRegex.type === "basic") {
        setBasicRegex(currentRegex.value || "");
      } else if (currentRegex.type === "multiline_match") {
        setExtractionPattern(currentRegex.extraction_pattern || "");
        setContinuationPattern(currentRegex.continuation_pattern || null);
        setDelimiter(currentRegex.delimiter || "");
        setValueToExtractPattern(currentRegex.value_to_extract_pattern);
      } else if (currentRegex.type === "multiline_match_fragment") {
        setExtractionPattern(currentRegex.extraction_pattern || "");
        setLineDetectorPattern(currentRegex.line_detector_pattern || "");
        setContinuationPattern(currentRegex.continuation_pattern || null);
      }
    }
  }, [currentRegex]);

  // Test Regex via Redux-Saga
  const handleTestRegex = () => {
    const regexPayload: TestRegexPayload = {
      field_name: field.key,
      line_detector_re: lineDetectorRegex,
      type: regexType,
      value: regexType === "basic" ? basicRegex : undefined,
      extraction_pattern: ["multiline_match", "multiline_match_fragment"].includes(regexType)
        ? extractionPattern
        : undefined,
      continuation_pattern: continuationPattern,
      line_detector_pattern: regexType === "multiline_match_fragment" ? lineDetectorPattern : null,
      delimiter,
      value_to_extract_pattern: regexType === "multiline_match" ? valueToExtractPattern : undefined,
    };

    dispatch(parserActions.testRegexRequest({ ...regexPayload, text: selectedText }));
  };

  const handleSaveResults = () => {
    dispatch(
      parserActions.updateSampleField({
        parserId: parserId,
        sampleId: sampleId,
        fieldId: field.id,
        fieldKey: field.key,
        data: { expectedData: testResult },
      })
    );
  };
  // Save Regex via Redux-Saga
  const handleSave = () => {
    if (error) {
      snackbarUtils.error("Please correct the regex before saving.");
      return;
    }

    const data = {
      [field.key]: {
        regex: {
          type: regexType,
          ...(regexType === "basic"
            ? { value: basicRegex }
            : {
                extraction_pattern: extractionPattern,
                continuation_pattern: continuationPattern,
                line_detector_pattern: lineDetectorPattern,
                delimiter,
                value_to_extract_pattern: valueToExtractPattern,
              }),
        },
      },
    };

    dispatch(
      parserActions.updateParserConfigRequest({
        parserId,
        data,
        action_type: "update",
      })
    );
    snackbarUtils.success("Regex saved successfully!");

    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{field.name} Field</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper>
              <Typography variant="h6">Selected Text for field</Typography>
              <TextField fullWidth multiline value={selectedText} disabled />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Typography variant="h6">Regex Configuration</Typography>
              <TextField
                select
                label="Regex Type"
                fullWidth
                value={regexType}
                onChange={(e) =>
                  setRegexType(
                    e.target.value as "basic" | "multiline_match" | "multiline_match_fragment"
                  )
                }
                margin="normal"
              >
                <MenuItem value="basic">Basic Match</MenuItem>
                <MenuItem value="multiline_match">Multiline Match</MenuItem>
                <MenuItem value="multiline_match_fragment">Multiline Fragment Match</MenuItem>
              </TextField>

              {regexType === "basic" && (
                <TextField
                  label="Regular Expression"
                  fullWidth
                  value={basicRegex}
                  onChange={(e) => setBasicRegex(e.target.value)}
                  margin="normal"
                />
              )}

              {regexType === "multiline_match" && (
                <>
                  <TextField
                    label="Extraction Pattern"
                    fullWidth
                    value={extractionPattern}
                    onChange={(e) => setExtractionPattern(e.target.value)}
                    margin="normal"
                  />

                  <TextField
                    label="Continuation Pattern (Optional)"
                    fullWidth
                    value={continuationPattern || ""}
                    onChange={(e) => setContinuationPattern(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Delimiter (Optional)"
                    fullWidth
                    value={delimiter}
                    onChange={(e) => setDelimiter(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Value To Extract (Optional)"
                    fullWidth
                    value={valueToExtractPattern}
                    onChange={(e) => setValueToExtractPattern(e.target.value)}
                    margin="normal"
                  />
                </>
              )}
              {regexType === "multiline_match_fragment" && (
                <>
                  <TextField
                    label="Extraction Pattern"
                    fullWidth
                    value={extractionPattern}
                    onChange={(e) => setExtractionPattern(e.target.value)}
                    margin="normal"
                  />

                  <TextField
                    label="Fragment Pattern "
                    fullWidth
                    value={continuationPattern || ""}
                    onChange={(e) => setContinuationPattern(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Line Detector Pattern"
                    fullWidth
                    value={lineDetectorPattern}
                    onChange={(e) => setLineDetectorPattern(e.target.value)}
                    margin="normal"
                  />
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 1,
                }}
              >
                <Button variant="contained" onClick={handleTestRegex} color="primary">
                  {loading ? <CircularProgress size={24} /> : "Test Regex"}
                </Button>
              </Box>

              {testResult && (
                <>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Result(s) </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testResult.map((item) => (
                          <TableRow
                            key={item}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {item}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {testResult && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 1,
                      }}
                    >
                      <Button variant="contained" onClick={handleSaveResults} color="primary">
                        {loading ? <CircularProgress size={24} /> : "Save Results"}
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {error && (
                <Typography color="red" mt={2}>
                  {error}
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={!!error}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegexDialog;
